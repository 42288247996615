import React from "react";
import { Chip, SxProps } from "@mui/material";

export type Status = "success" | "warning" | "error";

export interface Props {
  status: Status;
  label: string;
  sx?: SxProps;
}
const colors = {
  success: {
    backgroundColor: "green",
    color: "white",
  },
  warning: {
    backgroundColor: "yellow",
    color: "black",
  },
  error: {
    backgroundColor: "red",
    color: "white",
  },
};

export const StatusChip = ({ label, status, sx }: Props) => {
  return (
    <Chip label={label} sx={{ ...colors[status], fontWeight: "bold", ...sx }} />
  );
};
