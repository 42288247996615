import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import {
  Analysis,
  Template,
  User,
  Campaign,
  AggregateComments,
  ItemType,
  OverallValues,
} from "../../types";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Paper,
  Typography,
} from "@mui/material";
import {
  VictoryAxis,
  VictoryBar,
  VictoryChart,
  VictoryLabel,
  VictoryScatter,
  VictoryTheme,
  VictoryTooltip,
} from "victory";
import { DownloadLink } from "../DownloadLink";

import { useResizeDetector } from "react-resize-detector";
import { getUserName } from "../../DataTransform";
import FeedbackLoopColors from "./Colors";
import {
  AlignmentColumns,
  IndividualSelfPeerColumns,
  IndividualTextColumns,
} from "./Columns";
import { AnalysisScoreChart } from "./ScoreChart";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";
import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";
import { Celebration } from "@mui/icons-material";
import ConstructionIcon from "@mui/icons-material/Construction";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import "./CellStyle.css";

// https://stackoverflow.com/questions/14964035/how-to-export-javascript-array-info-to-csv-on-client-side
function arrayToCsv(data: string[][]) {
  const rows = [
    "Category,Question,Self,Peers,Gap,Response,Sender",
    ...data.map(
      (row) =>
        row
          .map(String) // convert every value to String
          .map((v) => v.replaceAll('"', '""')) // escape double colons
          .map((v) => `"${v}"`) // quote it
          .join(","), // comma-separated
    ),
  ];

  return rows.join("\r\n"); // rows starting on new lines
}

function analysesToArray(
  analyses: Analysis[],
  aggregates: AggregateComments[],
): string[][] {
  const output = [
    ...analyses.map((analysis) => [
      analysis.question.category,
      analysis.question.label,
      // getUserName(analysis.user),
      analysis.self?.toLocaleString() ?? "",
      analysis.others?.toLocaleString() ?? "",
      analysis.gap?.toLocaleString() ?? "",
      "",
      "(AVERAGE)",
    ]),

    ...aggregates.flatMap((aggregate) =>
      aggregate.comments.map((comment) => [
        aggregate.question.category,
        aggregate.question.label,
        // getUserName(comment.recipient),
        "",
        "",
        "",
        comment.comment,
        getUserName(comment.respondent),
      ]),
    ),
  ];

  return output;
}

function analysesToObjectArray(
  analyses: Analysis[],
  aggregates: AggregateComments[],
  sections: string[],
) {
  // console.log("AGGREGATES", aggregates);
  return sections.map((section, id) => ({
    id,
    section,
    analyses: analyses
      .filter((analysis) => analysis.question.category === section)
      .map((analysis, id) => ({
        id,
        section: analysis.question.category,
        question: analysis.question.label,
        user: getUserName(analysis.user),
        self: analysis.self,
        peers: analysis.others,
        gap: analysis.gap,
      })),
    aggregates: aggregates
      .filter((aggregate) => aggregate.question.category === section)
      .map((aggregate, id) => ({
        id,
        section: aggregate.question.category,
        question: aggregate.question.label,
        // user: getUserName(aggregate.user),
        comments: aggregate.comments.map((comment) => ({
          ...comment,
          recipient: getUserName(comment.recipient),
          respondent: getUserName(comment.respondent),
        })),
      })),
  }));
}

export interface Props {
  title: string;
  campaign: Campaign;
  user: User;
  analyses: Analysis[];
  aggregates: AggregateComments[];
  overall: OverallValues;
  template: Template;
}

const GridHeaderFactory = (
  section: string,
  color?: string,
  icon?: JSX.Element,
) => {
  const GridHeader = () => (
    <Typography
      variant="subtitle1"
      sx={{
        pl: 1,
        pt: 0.5,
        pb: 0.5,
        backgroundColor: color ? color : "#222",
      }}
    >
      {section} {icon}
    </Typography>
  );
  return GridHeader;
};

export const AnonAnalysisView = ({
  title,
  user,
  analyses,
  aggregates,
  overall,
  template,
}: Props) => {
  const questions = template.items;
  const sections = questions
    .map((question) => question.category)
    .filter((category, index, array) => array.indexOf(category) === index);

  const groupCount = questions.filter(
    (question) => question.itemType === ItemType.Choice,
  ).length;

  const id = "analysis-view";
  const { width: chartWidth, ref: chartRef } =
    useResizeDetector<HTMLDivElement>();
  const safeChartWidth = chartWidth ? chartWidth : 800;

  const groupOffset = 20;
  const chartHeight = groupCount * (groupOffset + 4) * (1 + 2) + 150;
  const safeChartHeight = chartHeight && chartHeight >= 200 ? chartHeight : 200;

  const analysisObjectArray = analysesToObjectArray(
    analyses,
    aggregates,
    sections,
  );

  function friendlyLabel(text: string) {
    const lines = divideIntoLines(text, 20);
    if (lines.length > 2) lines[1] = lines[1] + "...";
    return lines.slice(0, 2);
  }

  function prependWarning(text: string, outlier: boolean | undefined) {
    return outlier ? `⚠️ ${text}` : text;
  }

  function divideIntoLines(text: string, targetLength: number) {
    const words = text.split(" ");
    const lines = [];
    let line = "";

    for (let i = 0; i < words.length; i++) {
      if (words[i].length > targetLength) {
        // split the word into chunks that fit the target length
        const chunks = chunkString(words[i], targetLength);
        for (let j = 0; j < chunks.length; j++) {
          lines.push(chunks[j]);
        }
      } else if (line.length + words[i].length + 1 <= targetLength) {
        line += words[i] + " ";
      } else {
        lines.push(line.trim());
        line = "";
        i--;
      }
    }

    lines.push(line.trim());
    return lines;
  }

  function chunkString(str: string, length: number) {
    const chunks = [];
    for (let i = 0; i < str.length; i += length) {
      chunks.push(str.substring(i, i + length) + "-");
    }
    chunks[chunks.length - 1] = chunks[chunks.length - 1].slice(0, -1);
    return chunks;
  }

  function OverallBlock() {
    return (
      <Paper
        sx={{
          pt: 1,
          display: "flex",
          justifyContent: "space-around",
          flexWrap: "wrap",
        }}
      >
        <Box>
          <AnalysisScoreChart value={overall.self} label={"Self"} />
        </Box>
        <Box>
          <AnalysisScoreChart value={overall.given} label={"Given"} />
        </Box>
        <Box>
          <AnalysisScoreChart value={overall.received} label={"Received"} />
        </Box>
      </Paper>
    );
  }

  function InsightSummaryBlock() {
    function sectionAverage(
      analyses: {
        id: number;
        section: string;
        question: string;
        user: string;
        self: number | undefined;
        peers: number | undefined;
        gap: number | undefined;
      }[],
    ) {
      const alignments = analyses
        .filter((analysis) => analysis && Number.isFinite(analysis.gap))
        .map(
          (analysis) =>
            analysis !== undefined &&
            analysis.gap !== undefined &&
            analysis.self !== undefined &&
            1 + analysis?.gap / analysis?.self,
        ) as number[];
      return (
        alignments.reduce(
          (a, b) => (a !== undefined && b !== undefined ? a + b : 0),
          0,
        ) / alignments.length
      );
    }

    const alignmentBySection = analysisObjectArray.map((sectionObject) => ({
      id: sectionObject.id,
      section:
        analysisObjectArray.length > 1 ? sectionObject.section : "Overall",
      alignment: sectionAverage(sectionObject.analyses),
    }));

    return (
      <DataGrid
        autoHeight={true}
        // key={section.id}
        initialState={{
          sorting: {
            sortModel: [{ field: "alignment", sort: "desc" }],
          },
        }}
        hideFooter={true}
        rows={alignmentBySection}
        components={{
          Toolbar: GridHeaderFactory("Average Alignment by Section"),
        }}
        sx={{ backgroundColor: "black", mb: 1 }}
        columns={AlignmentColumns}
        pagination={undefined}
        getRowHeight={() => "auto"}
      />
    );
  }

  function OpportunitiesBlock() {
    const normalizedAlignments = analyses
      .filter(
        (analysis) =>
          analysis && analysis.gap !== undefined && analysis.self !== undefined,
      )
      .map((analysis) => ({
        question: analysis.question.label,
        alignment: 1 + (analysis.gap as number) / (analysis.self as number),
        others: analysis.others,
        self: analysis.self,
      }));

    const filteredNegativeAlignments = normalizedAlignments.filter(
      (alignment) => alignment.alignment < 0.85,
    );
    const filteredPositiveAlignments = normalizedAlignments.filter(
      (alignment) => alignment.alignment > 1.15,
    );
    const bottom3NegativeAlignments = filteredNegativeAlignments
      .sort((a, b) => a.alignment - b.alignment)
      .slice(0, 3);
    const top3PositiveAlignments = filteredPositiveAlignments
      .sort((a, b) => b.alignment - a.alignment)
      .slice(0, 3);

    function AlignmentBlock({
      opportunity,
    }: {
      opportunity: {
        question: string;
        alignment: number;
        others?: number;
        self?: number;
      };
    }) {
      return (
        <Card variant="outlined" sx={{ mb: 2 }}>
          <CardHeader
            avatar={
              opportunity.alignment >= 1 ? (
                <ArrowCircleUpIcon sx={{ color: "green" }} />
              ) : (
                <ArrowCircleDownIcon sx={{ color: "red" }} />
              )
            }
            title={
              opportunity.alignment < 1
                ? `Potential weakness: ${opportunity.question}`
                : `Hidden Skill: ${opportunity.question}`
            }
            subheader={`Alignment: ${(100 * opportunity.alignment).toFixed(
              0,
            )}%`}
          />
          <CardContent>
            <VictoryChart
              horizontal
              theme={VictoryTheme.material}
              height={150}
              width={safeChartWidth}
              domain={{ x: [0.5, 1.5], y: [0.5, 5.5] }}
              style={{ parent: { marginTop: -40 } }}
            >
              <VictoryAxis
                style={{
                  axis: { stroke: "white" },
                  axisLabel: { padding: 20 },
                  grid: { stroke: "white" },
                  ticks: { stroke: "none", size: 5 },
                  tickLabels: { padding: 5, stroke: "white", fill: "white" },
                }}
                tickLabelComponent={<></>}
              />

              <VictoryAxis
                dependentAxis
                label="Rating"
                style={{
                  axis: { stroke: "white" },
                  axisLabel: { padding: 30 },
                  grid: { stroke: "white" },
                  ticks: { stroke: "white", size: 5 },
                  tickLabels: { padding: 5, stroke: "white", fill: "white" },
                }}
              />

              <VictoryBar
                barWidth={20}
                colorScale={FeedbackLoopColors}
                key={user.id}
                labelComponent={
                  <VictoryTooltip constrainToVisibleArea orientation={"top"} />
                }
                name="bar"
                data={[
                  {
                    x: opportunity.question,
                    y: opportunity.others,
                    label: `${
                      opportunity.question
                    } (PEERS: ${opportunity.others?.toFixed(1)})`,
                  },
                ]}
                style={{
                  data: {
                    stroke: "white",
                    strokeDasharray: 0,
                    strokeWidth: 2,
                    fill: FeedbackLoopColors[0],
                  },
                }}
              />

              <VictoryScatter
                key={user.id + "others"}
                style={{
                  data: {
                    fill: "white",
                  },
                }}
                size={6}
                labelComponent={
                  <VictoryTooltip constrainToVisibleArea orientation={"top"} />
                }
                name="scatter"
                data={[
                  {
                    x: opportunity.question,
                    y: opportunity.self,
                    label: `${
                      opportunity.question
                    } (SELF: ${opportunity.self?.toFixed(1)})`,
                    others: opportunity.others,
                  },
                ]}
              />
            </VictoryChart>

            <Typography variant="caption" sx={{ mt: 2, ml: 4 }}>
              {opportunity.alignment >= 1
                ? `Your peers rated you ${(
                    (opportunity.alignment - 1) *
                    100
                  ).toFixed(0)}% higher than you rated yourself.`
                : `Your peers rated you ${(
                    -(opportunity.alignment - 1) * 100
                  ).toFixed(0)}% lower than you rated yourself.`}
            </Typography>
          </CardContent>
        </Card>
      );
    }

    function InsightsHero({
      message,
      sentiment = "neutral",
    }: {
      message: string;
      sentiment?: "positive" | "negative" | "neutral";
    }) {
      return (
        <Paper sx={{ mb: 2 }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
            }}
          >
            {sentiment === "positive" && (
              <Celebration sx={{ fontSize: 80, color: "green", mt: 2 }} />
            )}
            {sentiment === "negative" && (
              <ConstructionIcon sx={{ fontSize: 80, color: "gray", mt: 2 }} />
            )}
            {sentiment === "neutral" && (
              <ThumbUpIcon sx={{ fontSize: 80, color: "green", mt: 2 }} />
            )}

            <Typography variant="h6" sx={{ m: 2 }}>
              {message}
            </Typography>
          </Box>
        </Paper>
      );
    }

    return (
      <>
        <>
          {top3PositiveAlignments.length == 0 &&
            bottom3NegativeAlignments.length == 0 && (
              <InsightsHero
                message={
                  "Amazing! Your self ratings and received ratings were well aligned."
                }
                sentiment="neutral"
              />
            )}
          {top3PositiveAlignments.length > 0 && (
            <InsightsHero
              message={
                "Congratulations! Your peers have rated you more highly than you rated yourself across one or more facets, suggesting you have hidden skills."
              }
              sentiment="positive"
            />
          )}
          {top3PositiveAlignments.map((opportunity, id) => (
            <AlignmentBlock key={id} opportunity={opportunity} />
          ))}
          {bottom3NegativeAlignments.length > 0 && (
            <InsightsHero
              message={
                "Your peers have rated you lower than you rated yourself across one or more facets, suggesting there may opportunities for improvement based on the results below."
              }
              sentiment="negative"
            />
          )}
          {bottom3NegativeAlignments.map((opportunity, id) => (
            <AlignmentBlock key={id} opportunity={opportunity} />
          ))}
        </>
      </>
    );
  }

  return (
    <>
      <Box sx={{ pt: 5 }}></Box>

      <Box
        sx={{ display: "flex", flexDirection: "row", justifyContent: "center" }}
      >
        <DownloadLink
          filename={`${title}.csv`}
          mimeType="text/csv"
          label="Download Excel"
          contents={arrayToCsv(analysesToArray(analyses, aggregates))}
          buttonProps={{
            variant: "contained",
            sx: { mt: 2, mr: 2 },
          }}
        />
      </Box>

      <Typography variant="h6">Average Ratings</Typography>
      <OverallBlock />

      <Typography variant="h6" sx={{ mt: 0.5 }}>
        Your Insights
      </Typography>
      <InsightSummaryBlock />
      <OpportunitiesBlock />

      {aggregates.length > 0 && (
        <>
          <Typography variant="h6" sx={{ mt: 0.5 }}>
            Your Written Feedback
          </Typography>
          <div>
            {analysisObjectArray.map((section) =>
              section.aggregates.map((aggregate) => (
                <DataGrid
                  autoHeight={true}
                  key={aggregate.id}
                  hideFooter={true}
                  rows={aggregate.comments}
                  components={{
                    Toolbar: GridHeaderFactory(
                      `${section.section} - ${aggregate.question}`,
                    ),
                  }}
                  sx={{ backgroundColor: "black", mb: 1 }}
                  columns={IndividualTextColumns}
                  pagination={undefined}
                  getRowHeight={() => "auto"}
                />
              )),
            )}
          </div>
        </>
      )}

      <Typography variant="h6" sx={{ mt: 0.5 }}>
        Full Results
      </Typography>
      <div style={{ height: safeChartHeight }} ref={chartRef}>
        {analyses.length > 0 && (
          <VictoryChart
            horizontal
            theme={VictoryTheme.material}
            padding={{ bottom: 50, top: 40, left: 100, right: 0 }}
            height={safeChartHeight}
            width={safeChartWidth}
            domain={{ x: [0.5, groupCount + 0.5], y: [0.5, 5.5] }}
          >
            <VictoryAxis
              style={{
                axis: { stroke: "white" },
                axisLabel: { padding: 20 },
                grid: { stroke: "white" },
                ticks: { stroke: "white", size: 5 },
                tickLabels: { padding: 5, stroke: "white", fill: "white" },
              }}
              tickLabelComponent={
                <VictoryLabel
                  angle={-45}
                  textAnchor={"end"}
                  text={({ datum }) =>
                    analyses[datum - 1]
                      ? prependWarning(
                          friendlyLabel(
                            analyses[datum - 1].label,
                          ) as unknown as string,
                          analyses[datum - 1].outlier,
                        )
                      : ""
                  }
                />
              }
            />

            <VictoryAxis
              dependentAxis
              label="Rating"
              style={{
                axis: { stroke: "white" },
                axisLabel: { padding: 30 },
                grid: { stroke: "white" },
                ticks: { stroke: "white", size: 5 },
                tickLabels: { padding: 5, stroke: "white", fill: "white" },
              }}
            />

            <VictoryBar
              barWidth={20}
              colorScale={FeedbackLoopColors}
              key={user.id}
              labelComponent={
                <VictoryTooltip constrainToVisibleArea orientation={"top"} />
              }
              name="bar"
              data={analyses.map((question) => ({
                x: question.label,
                y: question.others,
                label: `${question.label} (PEERS: ${question.others?.toFixed(
                  1,
                )})`,
              }))}
              style={{
                data: {
                  stroke: "white",
                  strokeDasharray: 0,
                  strokeWidth: 2,
                  fill: FeedbackLoopColors[0],
                },
              }}
            />

            <VictoryScatter
              key={user.id + "others"}
              style={{
                data: {
                  fill: "white",
                },
              }}
              size={6}
              labelComponent={
                <VictoryTooltip constrainToVisibleArea orientation={"top"} />
              }
              name="scatter"
              data={analyses.map((question) => ({
                x: question.label,
                y: question.self,
                label: `${question.label} (SELF: ${question.self?.toFixed(1)})`,
                others: question.others,
              }))}
            />
          </VictoryChart>
        )}
      </div>

      <div id={id}>
        {analysisObjectArray.map((section) => (
          <>
            <DataGrid
              autoHeight={true}
              key={section.id}
              initialState={{
                sorting: {
                  sortModel: [{ field: "gap", sort: "desc" }],
                },
              }}
              hideFooter={true}
              rows={section.analyses}
              components={{
                Toolbar: GridHeaderFactory(section.section),
              }}
              sx={{ backgroundColor: "black", mb: 1 }}
              columns={IndividualSelfPeerColumns}
              pagination={undefined}
            />
          </>
        ))}
      </div>
    </>
  );
};
