import React from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SxProps,
} from "@mui/material";
import { ItemSubjectType, ResponseSubjectType, Template } from "../../types";

export interface Props {
  label: string;
  form?: Template;
  selected?: ResponseSubjectType;
  onChange: (subjectType: ResponseSubjectType) => void;
  sx?: SxProps;
}

export const SubjectTypeSelector = ({
  label,
  form,
  selected = ResponseSubjectType.Peer,
  onChange,
  sx,
}: Props) => {
  const peerSelfEntry =
    form &&
    form.items.some(
      (item) =>
        item.subjectType === ItemSubjectType.PeerSelf ||
        item.subjectType === ItemSubjectType.PeerOnly,
    )
      ? [{ id: ResponseSubjectType.Peer, label: "Peer and self-feedback" }]
      : [];
  // const peerOnlyEntry =
  //   form &&
  //   form.items.some((item) => item.subjectType === ItemSubjectType.PeerOnly)
  //     ? [{ id: ResponseSubjectType.Peer, label: "Peer only feedback" }]
  //     : [];
  const generalEntry =
    form &&
    form.items.some((item) => item.subjectType === ItemSubjectType.General)
      ? [{ id: ResponseSubjectType.General, label: "General feedback" }]
      : [];
  const types = [...peerSelfEntry, ...generalEntry];

  return (
    <FormControl style={{ minWidth: 190, maxWidth: 190 }} size="small" sx={sx}>
      <InputLabel id="campaign-select-label">{label}</InputLabel>
      <Select
        labelId="campaign-select-label"
        id="campaign-select"
        value={selected}
        label={label}
        onChange={(e) => onChange(e.target.value as ResponseSubjectType)}
      >
        {types.map((subjectType, index) => (
          <MenuItem key={index} value={subjectType.id}>
            {subjectType.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
