import React from "react";
import { Clear, Delete, MoreVert, Star } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { Team as TeamType, User } from "../../types";
import { UserList } from "../User/List";
import { UserAutocomplete } from "../User/Autocomplete";
import { getLabelWithRemainingChars } from "../../Helpers";

export interface Props {
  team: TeamType;
  users: User[];
  onChange?: (team: TeamType) => void;
  onDelete?: (team: TeamType) => void;
  onAssign: (userIds: string[], team: TeamType) => void;
  onSetLeader?: (userId: string, team: TeamType) => void;
}

export const TeamEdit = ({
  team,
  onAssign,
  onChange,
  onDelete,
  onSetLeader,
  users,
}: Props) => {
  const theme = useTheme();
  const teamUsers = users.filter((user) => team.userIds.indexOf(user.id) >= 0);
  const [menuAnchor, setMenuAnchor] = React.useState<
    [HTMLElement, User] | null
  >(null);
  const menuOpen = Boolean(menuAnchor);

  function handleDeleteUser(user: User) {
    const clone = {
      ...team,
      userIds: team.userIds.filter((target) => target !== user.id),
    };
    onChange && onChange(clone);
  }

  function handleAssignLeader(user: User) {
    onSetLeader && onSetLeader(user.id, team);
  }

  function handleMenuClick(user: User, e: React.MouseEvent<HTMLElement>) {
    setMenuAnchor([e.currentTarget, user]);
  }

  function handleMenuClose() {
    setMenuAnchor(null);
  }

  function setTeamTitle(label: string) {
    onChange && onChange({ ...team, name: label });
  }

  function EmptyState() {
    return (
      <Box sx={{ mb: 3 }}>
        <Typography variant="h5" sx={{ textAlign: "center", mb: 2 }}>
          It&apos;s looking pretty empty in here...
        </Typography>
        <Typography>
          Assign members to this team by selecting them from this dropdown.
        </Typography>
      </Box>
    );
  }

  return (
    <Card
      sx={{
        mb: 2,
        border: `3px solid ${
          team.userIds.length ? "transparent" : theme.palette.primary.main
        }`,
      }}
    >
      <CardHeader
        title={
          onChange ? (
            <TextField
              variant="outlined"
              label={getLabelWithRemainingChars(
                "Team Name",
                team.name.length,
                255,
              )}
              value={team.name}
              fullWidth={true}
              onBlur={(e) => setTeamTitle(e.target.value.trim())}
              onChange={(e) => setTeamTitle(e.target.value)}
              inputProps={{ maxLength: 255 }}
            />
          ) : (
            team.name
          )
        }
        action={
          onDelete && (
            <Button
              variant="contained"
              sx={{ m: 1 }}
              endIcon={<Delete />}
              onClick={() => onDelete(team)}
            >
              Remove Team
            </Button>
          )
        }
      />
      <CardContent>
        {!teamUsers.length && <EmptyState />}
        <Box sx={{ textAlign: "center", mb: 2 }}>
          <UserAutocomplete
            label="Assign Team Members"
            onChange={(user) => onAssign([user.id], team)}
            showAvatars={true}
            users={users.filter(
              (target) => team.userIds.indexOf(target.id) < 0,
            )}
          />
        </Box>
        <UserList
          users={teamUsers}
          leader={team.leaderId}
          UserActions={({ user }) =>
            onChange && (
              <IconButton
                onClick={(e) => handleMenuClick(user, e)}
                size="small"
                sx={{ ml: 2 }}
                aria-controls={menuOpen ? "user-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={menuOpen ? "true" : undefined}
              >
                <MoreVert />
              </IconButton>
            )
          }
        />
      </CardContent>
      <Menu
        anchorEl={menuAnchor && menuAnchor[0]}
        id="account-menu"
        open={menuOpen}
        onClose={handleMenuClose}
        onClick={handleMenuClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem
          onClick={() => menuAnchor && handleAssignLeader(menuAnchor[1])}
        >
          <ListItemIcon>
            <Star fontSize="small" />
          </ListItemIcon>
          Assign student as team lead
        </MenuItem>
        <Divider />
        <MenuItem onClick={() => menuAnchor && handleDeleteUser(menuAnchor[1])}>
          <ListItemIcon>
            <Clear fontSize="small" />
          </ListItemIcon>
          Remove student from team
        </MenuItem>
      </Menu>
    </Card>
  );
};
