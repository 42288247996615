import React, { useState } from "react";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Paper,
  TextField,
} from "@mui/material";
import { getLabelWithRemainingChars, Group } from "../../Helpers";
import {
  FormItem,
  ItemType,
  NewCategory,
  Template as TemplateType,
} from "../../types";
import { QuestionView } from "../Question/View";
import { QuestionEdit } from "../Question/Edit";

import { Draggable, Droppable } from "@hello-pangea/dnd";
import { Delete, DragIndicator, Edit } from "@mui/icons-material";

export interface Props {
  category: Group<FormItem>;
  index: number;
  template: TemplateType;
  onChange: (template: TemplateType) => void;
  onAdd: (event: React.MouseEvent<HTMLButtonElement>, category: string) => void;
  editing: string[];
  setEditing: (x: string[]) => void;
}

export const TemplateCategory = ({
  category,
  index,
  onAdd,
  onChange,
  template,
  editing,
  setEditing,
}: Props) => {
  const [editCategory, setEditCategory] = useState(
    category.key.startsWith(NewCategory),
  );
  const [categoryName, setCategoryName] = useState(category.key);
  function handleQuestionChange(question: FormItem) {
    const clone = { ...template };
    clone.items = clone.items.map((target) =>
      target.id === question.id ? question : target,
    );
    onChange(clone);
  }

  function handleQuestionEdit(question: FormItem) {
    setEditing([...editing, question.id]);
  }

  function handleQuestionDelete(question: FormItem) {
    const clone = { ...template };
    clone.items = clone.items.filter((target) => target.id !== question.id);
    onChange(clone);
  }

  function handleQuestionDone(question: FormItem) {
    setEditing(editing.filter((target) => target !== question.id));
  }

  function handleRenameCategory(oldName: string, newName: string) {
    const clone = {
      ...template,
      items: template.items.map((x) => ({
        ...x,
        category: x.category === oldName ? newName : x.category,
      })),
    };
    onChange(clone);
  }

  function handleDeleteCategoryClick(category: string) {
    const clone = { ...template };
    clone.items = clone.items.filter((q) => q.category !== category);
    onChange(clone);
  }

  function isCategoryEmpty(category: string) {
    return !template.items
      .filter((q) => q.itemType !== ItemType.Blank)
      .some((q) => q.category === category);
  }

  return (
    <Draggable
      draggableId={category.key}
      index={index}
      isDragDisabled={!template.isMine}
    >
      {(provided) => (
        <Card
          sx={{ mb: 2 }}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
        >
          {editCategory ? (
            <CardHeader
              avatar={<DragIndicator />}
              title={
                <TextField
                  label={getLabelWithRemainingChars(
                    "Category Name",
                    categoryName.length,
                    255,
                  )}
                  onChange={(e) => setCategoryName(e.target.value)}
                  defaultValue={category.key}
                  onBlur={(e) =>
                    handleRenameCategory(category.key, e.target.value)
                  }
                  autoFocus
                  inputProps={{
                    maxLength: 255,
                    onKeyUp: (event) => {
                      if (event.key === "Enter" || event.key === "Escape") {
                        if (event.currentTarget.value === category.key) {
                          setEditCategory(false);
                        } else event.currentTarget.blur();
                      }
                    },
                  }}
                  size="small"
                  fullWidth
                />
              }
            />
          ) : (
            <CardHeader
              avatar={
                <IconButton
                  sx={{
                    cursor: "grab",
                    "&:active": {
                      cursor: "grabbing",
                    },
                    p: 0,
                  }}
                  disabled={!template.isMine}
                >
                  <DragIndicator />
                </IconButton>
              }
              title={category.key}
              action={
                <>
                  <Button
                    variant="outlined"
                    onClick={(e) => onAdd(e, categoryName)}
                    disabled={!template.isMine}
                    sx={{ color: "white", borderColor: "white", mr: 1 }}
                  >
                    Add Question
                  </Button>
                  <IconButton
                    onClick={() => setEditCategory(true)}
                    disabled={!template.isMine}
                  >
                    <Edit />
                  </IconButton>
                  <IconButton
                    onClick={() => handleDeleteCategoryClick(category.key)}
                    disabled={
                      !isCategoryEmpty(category.key) || !template.isMine
                    }
                  >
                    <Delete />
                  </IconButton>
                </>
              }
            />
          )}
          <Droppable
            droppableId={category.key}
            key={category.key}
            type="QUESTION"
          >
            {(provided) => (
              <CardContent ref={provided.innerRef} {...provided.droppableProps}>
                {category.members
                  .filter((x) => x.itemType !== ItemType.Blank)
                  .map((question, index) => (
                    <Draggable
                      key={question.id}
                      draggableId={question.id}
                      index={index}
                      isDragDisabled={!template.isMine}
                    >
                      {(provided) => (
                        <Paper
                          variant="outlined"
                          sx={{ p: 2, mb: 1 }}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          ref={provided.innerRef}
                        >
                          {editing.indexOf(question.id) >= 0 ? (
                            <QuestionEdit
                              key={index}
                              formItem={question}
                              onChange={handleQuestionChange}
                              onDoneClick={handleQuestionDone}
                              onDeleteClick={() =>
                                handleQuestionDelete(question)
                              }
                              template={template}
                            />
                          ) : (
                            <>
                              <IconButton
                                sx={{
                                  cursor: "grab",
                                  "&:active": {
                                    cursor: "grabbing",
                                  },
                                  p: 0,
                                  float: "left",
                                  ml: -0.5,
                                  mr: 2,
                                }}
                                disabled={!template.isMine}
                              >
                                <DragIndicator />
                              </IconButton>
                              <QuestionView
                                editable={template.isMine}
                                formItem={question}
                                onEditClick={() => handleQuestionEdit(question)}
                                onDeleteClick={() =>
                                  handleQuestionDelete(question)
                                }
                                sx={{
                                  mb: -1,
                                }}
                              />
                            </>
                          )}
                        </Paper>
                      )}
                    </Draggable>
                  ))}
                {provided.placeholder}
              </CardContent>
            )}
          </Droppable>
        </Card>
      )}
    </Draggable>
  );
};
