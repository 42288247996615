import * as React from "react";
import { AppBar, Box, Typography } from "@mui/material";
import { ModalProgress } from "../Components/ModalProgress";
import FeedbackLoopColors from "../Components/Analysis/Colors";

export interface Props {
  actionComponent?: React.ReactNode;
  loading?: boolean;
  secondaryTitle?: string;
  secondaryActionComponent?: React.ReactNode;
  title: string;
  children: React.ReactNode;
}

export function ThinLayout({
  children,
  actionComponent = undefined,
  loading = false,
  secondaryActionComponent = undefined,
  secondaryTitle = undefined,
  title,
}: Props) {
  React.useEffect(() => {
    document.title = `Feedback Loop -> ${title}`;
  }, [title]);

  return (
    <Box>
      <AppBar position="fixed" sx={{ p: 1 }}>
        {secondaryTitle && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              backgroundColor: FeedbackLoopColors[0],
              p: "10px 10px 0px",
              m: "-10px -10px 10px",
            }}
          >
            <div />
            <Typography variant="subtitle1">{secondaryTitle}</Typography>
            {secondaryActionComponent}
          </Box>
        )}
        <Typography variant="h5">
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            {title}
            {actionComponent}
          </Box>
        </Typography>
      </AppBar>
      <Box
        component="main"
        padding={2}
        sx={{ width: "100%", maxWidth: 950, m: "0 auto" }}
      >
        <Box sx={{ mb: secondaryTitle ? 8 : 4 }} />
        {loading && <ModalProgress />}
        {children}
      </Box>
    </Box>
  );
}
