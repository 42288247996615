import React from "react";
import { SxProps } from "@mui/material";
import { Campaign, CampaignState as CampaignStateType } from "../../types";
import { Status, StatusChip } from "../StatusChip";

export interface Props {
  campaign: Campaign;
  sx?: SxProps;
}

const statuses: Record<CampaignStateType, { status: Status; label: string }> = {
  [CampaignStateType.Active]: { status: "success", label: "Active" },
  [CampaignStateType.Scheduled]: { status: "warning", label: "Scheduled" },
  [CampaignStateType.Draft]: { status: "warning", label: "Draft" },
  [CampaignStateType.Closed]: { status: "error", label: "Closed" },
};

export const CampaignState = ({ campaign, sx }: Props) => {
  return <StatusChip {...statuses[campaign.state]} sx={sx} />;
};
