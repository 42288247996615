import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material";

export interface Props {
  isPending: boolean;
  errorMessage?: string;
  onClaim: (tokenString: string) => void;
}

export const LicenseClaim = ({ isPending, errorMessage, onClaim }: Props) => {
  const [tokenString, setTokenString] = useState<string>("");

  async function claimClick() {
    onClaim(tokenString);
  }

  return (
    <Dialog
      open={true}
      PaperProps={{
        onSubmit: (event: React.FormEvent<HTMLDivElement>) => {
          event.preventDefault();
          claimClick();
        },
      }}
    >
      <form>
        <DialogTitle>License Required</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please provide the Feedback Loop license token you purchased in
            order to continue.
          </DialogContentText>
          <TextField
            autoFocus
            required
            margin="dense"
            onChange={(e) => setTokenString(e.target.value)}
            label="License Token"
            value={tokenString}
            fullWidth
            variant="standard"
            disabled={isPending}
          />
          {errorMessage && (
            <Typography variant="body2" color="error">
              {errorMessage}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button type="submit" disabled={isPending}>
            Continue
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
