import {
  CopyAll,
  Delete,
  Edit,
  MoreVert,
  Public,
  Business,
  Security,
} from "@mui/icons-material";
import {
  Card,
  CardActions,
  CardHeader,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import { Template as TemplateType } from "../../types";

export interface Props {
  template: TemplateType;
  onClone: () => void;
  onDelete?: () => void;
}

export const TemplateCard = ({ onClone, onDelete, template }: Props) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Card>
      <CardHeader
        action={
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? "template-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          >
            <MoreVert />
          </IconButton>
        }
        title={template.name}
        titleTypographyProps={{
          component: Link,
          to: `/templates/${template.id}`,
          style: { color: "inherit", textDecoration: "none" },
        }}
        subheader={template.user ? template.user.email : "Feedback Loop team"}
      />
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem component={Link} to={`/templates/${template.id}`}>
          <ListItemIcon>
            <Edit fontSize="small" />
          </ListItemIcon>
          Edit Template
        </MenuItem>
        <MenuItem onClick={onClone}>
          <ListItemIcon>
            <CopyAll fontSize="small" />
          </ListItemIcon>
          Clone Template
        </MenuItem>
        {onDelete && (
          <MenuItem onClick={onDelete}>
            <ListItemIcon>
              <Delete fontSize="small" />
            </ListItemIcon>
            Delete Template
          </MenuItem>
        )}
      </Menu>

      <CardActions>
        <Typography variant="body2" color="text.secondary" sx={{ m: 1 }}>
          {`Last Updated ${new Date(
            Date.parse(template.modified),
          ).toLocaleString()}`}
        </Typography>
        <div style={{ flex: 1 }}></div>
        {template.isGlobal ? (
          <Tooltip
            title={"This template is available to all Feedback Loop users."}
          >
            <Public />
          </Tooltip>
        ) : template.isShared ? (
          <Tooltip
            title={
              "This template is available to all courses at your institution."
            }
          >
            <Business />
          </Tooltip>
        ) : (
          <Tooltip title={"This template is only visible to you."}>
            <Security />
          </Tooltip>
        )}
      </CardActions>
    </Card>
  );
};
