export class Group<T> {
  key: string;
  members: T[] = [];
  constructor(key: string) {
    this.key = key;
  }
}

export function groupBy<T>(list: T[], func: (x: T) => string): Group<T>[] {
  const groups: Record<string, Group<T>> = {};
  const groupArray: Group<T>[] = [];
  list.forEach((item) => {
    const groupName = func(item);
    if (!groups[String(groupName)]) {
      groups[String(groupName)] = new Group<T>(groupName);
      groupArray.push(groups[String(groupName)]);
    }
    groups[String(groupName)].members.push(item);
  });
  return groupArray;
}

export function getLabelWithRemainingChars(
  label: string,
  count: number,
  max: number,
) {
  const remaining = max - count;
  return `${label} (${remaining} character${remaining === 1 ? "" : "s"} left)`;
}
