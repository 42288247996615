import React from "react";
import {
  FormControl,
  IconButton,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
} from "@mui/material";
import { Template } from "../../types";
import { useTranslation } from "react-i18next";
import { Preview } from "@mui/icons-material";

export interface Props {
  templates: Template[];
  selected?: Template;
  onChange: (template: Template) => void;
}

export const TemplateSelector = ({ templates, selected, onChange }: Props) => {
  const [t] = useTranslation();
  const sortedTemplates = [...templates].sort((x, y) =>
    x.name.localeCompare(y.name),
  );
  const yourTemplates = sortedTemplates.filter((template) => template.isMine);
  const institutionTemplates = sortedTemplates.filter(
    (template) =>
      template.isShared &&
      !template.isMine &&
      // !template.isDirectShare &&
      !template.isGlobal,
  );
  const globalTemplates = sortedTemplates.filter(
    (template) =>
      template.isGlobal &&
      !template.isMine &&
      // !template.isDirectShare &&
      !template.isShared,
  );

  return (
    <>
      <FormControl size="small" fullWidth sx={{ flexDirection: "row" }}>
        <InputLabel id="template-select-label">
          {t("templates.template")}
        </InputLabel>
        <Select
          labelId="template-select-label"
          id="template-select"
          value={selected ? selected.id : ""}
          label={t("templates.template")}
          onChange={(e) => {
            const target = sortedTemplates.find(
              (target) => target.id == e.target.value,
            );
            target && onChange(target);
          }}
          sx={{ flexGrow: 1 }}
        >
          {yourTemplates.length > 0 && (
            <ListSubheader>{t("templates.your")}</ListSubheader>
          )}
          {yourTemplates.map((template, index) => (
            <MenuItem key={template.id} value={template.id}>
              {template.name}
            </MenuItem>
          ))}

          {institutionTemplates.length > 0 && (
            <ListSubheader>{t("templates.institution")}</ListSubheader>
          )}
          {institutionTemplates.map((template, index) => (
            <MenuItem key={template.id} value={template.id}>
              {template.name}
            </MenuItem>
          ))}

          {globalTemplates.length > 0 && (
            <ListSubheader>{t("templates.institution")}</ListSubheader>
          )}
          {globalTemplates.map((template, index) => (
            <MenuItem key={template.id} value={template.id}>
              {template.name}
            </MenuItem>
          ))}
        </Select>
        <IconButton
          href={`/preview-template/${selected?.id}`}
          disabled={!selected}
        >
          <Preview />
        </IconButton>
      </FormControl>
    </>
  );
};
