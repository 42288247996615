import React from "react";

export interface MappedItem {
  label: string;
  value: React.ReactNode;
}

export interface Props {
  items: MappedItem[];
}

const tdStyle: React.CSSProperties = { paddingRight: 10, paddingTop: 10 };

export const MapList = ({ items }: Props) => {
  return (
    <table>
      {items.map((item, index) => (
        <tr key={index}>
          <td style={tdStyle}>{item.label}:</td>
          <td style={tdStyle}>{item.value}</td>
        </tr>
      ))}
    </table>
  );
};
