import React from "react";
import {
  Close,
  MoreVert,
  Send,
  LockOpen,
  Visibility,
  Preview,
} from "@mui/icons-material";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
} from "@mui/material";
import { Campaign, CampaignState as CampaignStateType } from "../../types";
import { Link } from "react-router-dom";
import { CampaignState } from "./State";
import { CampaignProgress } from "./Progress";
import { useTranslation } from "react-i18next";
import { findCampaignActivity } from "../../Data";

export interface Props {
  campaign: Campaign;
  onChange: (campaign: Campaign, activityLabel: string) => void;
  onDelete: (campaign: Campaign) => void;
  onRemind: (campaign: Campaign) => void;
}

export const CampaignCard = ({
  campaign,
  onChange,
  onDelete,
  onRemind,
}: Props) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [t] = useTranslation();
  const open = Boolean(anchorEl);

  const isClosable = campaign.state == CampaignStateType.Active;
  const isDeleteable = campaign.state == CampaignStateType.Draft;

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  function handleSendClick() {
    onChange(
      { ...campaign, state: CampaignStateType.Active },
      t("campaigns.events.send"),
    );
  }

  function handleScheduleClick() {
    onChange(
      { ...campaign, state: CampaignStateType.Scheduled },
      t("campaigns.events.schedule"),
    );
  }

  function handleCloseClick() {
    onChange(
      { ...campaign, state: CampaignStateType.Closed },
      t("campaigns.events.close"),
    );
  }

  function handleDeleteClick() {
    onDelete(campaign);
  }

  function handleReopenClick() {
    onChange(
      { ...campaign, state: CampaignStateType.Active },
      t("campaigns.events.reopen"),
    );
  }

  function handleReleaseClick() {
    onChange(
      { ...campaign, released: new Date().toISOString() },
      t("campaigns.events.reopen"),
    );
  }

  function handleRemindClick() {
    onRemind(campaign);
  }

  const sentActivity = findCampaignActivity(
    campaign,
    t("campaigns.events.send"),
  );

  return (
    <Card>
      <CardHeader
        action={
          <>
            <CampaignState campaign={campaign} />
            <IconButton
              onClick={handleClick}
              size="small"
              sx={{ ml: 2 }}
              aria-controls={open ? "campaign-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
            >
              <MoreVert />
            </IconButton>
          </>
        }
        title={campaign.name}
        titleTypographyProps={{
          component: Link,
          to: `/campaigns/${campaign.id}`,
          style: { color: "inherit", textDecoration: "none" },
        }}
        subheader={
          <>
            {campaign.form ? <>{campaign.form.name}</> : <>No template</>}
            <br />
            {sentActivity
              ? new Date(sentActivity.created).toLocaleString()
              : ""}
          </>
        }
      />
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem component={Link} to={`/campaigns/${campaign.id}`}>
          <ListItemIcon>
            <Visibility fontSize="small" />
          </ListItemIcon>
          View Survey
        </MenuItem>
        {campaign.state === CampaignStateType.Closed && (
          <MenuItem onClick={handleReopenClick}>
            <ListItemIcon>
              <LockOpen fontSize="small" />
            </ListItemIcon>
            Reopen
          </MenuItem>
        )}
        {campaign.state === CampaignStateType.Closed &&
          (campaign.released === null ? (
            <MenuItem onClick={handleReleaseClick}>
              <ListItemIcon>
                <Preview fontSize="small" />
              </ListItemIcon>
              Release Results
            </MenuItem>
          ) : (
            <MenuItem disabled onClick={handleReleaseClick}>
              <ListItemIcon>
                <Preview fontSize="small" />
              </ListItemIcon>
              Results Released
            </MenuItem>
          ))}
        {campaign.state === CampaignStateType.Draft && campaign.autoSendDate ? (
          <MenuItem onClick={handleScheduleClick}>
            <ListItemIcon>
              <Send fontSize="small" />
            </ListItemIcon>
            Schedule
          </MenuItem>
        ) : (
          <MenuItem onClick={handleSendClick}>
            <ListItemIcon>
              <Send fontSize="small" />
            </ListItemIcon>
            Send
          </MenuItem>
        )}
        {campaign.state === CampaignStateType.Scheduled && (
          <MenuItem onClick={handleSendClick}>
            <ListItemIcon>
              <Send fontSize="small" />
            </ListItemIcon>
            Send Now
          </MenuItem>
        )}
        {campaign.state === CampaignStateType.Active && (
          <MenuItem onClick={handleRemindClick}>
            <ListItemIcon>
              <Send fontSize="small" />
            </ListItemIcon>
            Send Reminder
          </MenuItem>
        )}
        {isClosable && (
          <MenuItem onClick={handleCloseClick}>
            <ListItemIcon>
              <Close fontSize="small" />
            </ListItemIcon>
            Close
          </MenuItem>
        )}
        {isDeleteable && (
          <>
            <Divider />
            <MenuItem onClick={handleDeleteClick}>
              <ListItemIcon>
                <Close fontSize="small" />
              </ListItemIcon>
              Delete
            </MenuItem>
          </>
        )}
      </Menu>
      <CardContent>
        <Box
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <CampaignProgress
            submitted={campaign.submittedResponses}
            total={campaign.totalResponses}
          />
        </Box>
      </CardContent>
    </Card>
  );
};
