import React from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SxProps,
} from "@mui/material";
import { Campaign } from "../../types";

export interface Props {
  label: string;
  campaigns: Campaign[];
  selected?: Campaign;
  onChange: (campaign: Campaign) => void;
  sx?: SxProps;
}

export const CampaignSelector = ({
  label,
  campaigns,
  selected,
  onChange,
  sx,
}: Props) => {
  return (
    <FormControl size="small" sx={{ ...sx, minWidth: 190, maxWidth: 190 }}>
      <InputLabel id="campaign-select-label">{label}</InputLabel>
      <Select
        labelId="campaign-select-label"
        id="campaign-select"
        value={selected?.id}
        label={label}
        onChange={(e) => {
          const target = campaigns.find(
            (target) => target.id == e.target.value,
          );
          target && onChange(target);
        }}
      >
        {campaigns.map((campaign, index) => (
          <MenuItem key={index} value={campaign.id}>
            {campaign.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
