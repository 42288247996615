import React, { useState } from "react";
import { TextField, Typography } from "@mui/material";
import { FormItem, ResponseSubject, User } from "../../types";
import { getUserName } from "../../DataTransform";
import { getSubjectResponseWithChangedValue } from "./Subject";

export interface Props {
  formItem: FormItem;
  onChange: (value: ResponseSubject) => void;
  responseSubjects: ResponseSubject[];
  subjects: User[];
}

type Allocations = {
  [key: string]: number;
};

export const ResponsePoints = ({
  formItem,
  onChange,
  responseSubjects,
  subjects,
}: Props) => {
  const getInitialAllocation = () => {
    const allocation: Allocations = {};
    responseSubjects.forEach((rs) => {
      rs.answers
        .filter((answer) => answer.itemId === formItem.id)
        .forEach((answer) => {
          allocation[rs.subjectUserId!] = answer.value!;
        });
    });
    return allocation;
  };
  const [points, setPoints] = useState<Allocations>(getInitialAllocation());

  const getTotalPoints = (allocations: Allocations) => {
    return Object.values(allocations).reduce(
      (acc, unit) => (Number.isFinite(unit) ? acc + unit : acc),
      0,
    );
  };

  const totalPoints = getTotalPoints(points);

  function getSubjectResponse(subjectId: string): ResponseSubject {
    return responseSubjects.find((rs) => rs.subjectUserId === subjectId)!;
  }

  const handleChange = (subjectId: string, value: number) => {
    const pointsClone = { ...points };
    pointsClone[subjectId] = value;
    if (getTotalPoints(pointsClone) > formItem.scale! * subjects.length) {
      return;
    }
    setPoints(pointsClone);
    const subjectResponse = getSubjectResponse(subjectId);

    const clone = getSubjectResponseWithChangedValue(
      subjectResponse,
      formItem,
      value,
    );
    onChange(clone);
  };

  const remainingPoints = formItem.scale! * subjects.length - totalPoints;
  const variance =
    Math.max(...Object.values(points)) - Math.min(...Object.values(points));

  return (
    <>
      <Typography>{formItem.label}</Typography>

      <Typography
        variant="subtitle2"
        sx={{ color: remainingPoints === 0 ? "white" : "red" }}
      >
        Points Remaining: {remainingPoints}
      </Typography>

      {!!formItem.minVariance && (
        <Typography
          variant="subtitle2"
          sx={{ color: variance >= formItem.minVariance ? "white" : "red" }}
        >
          Variance between top and bottom points:{" "}
          {Number.isSafeInteger(variance) ? variance : "--"} (minimum{" "}
          {formItem.minVariance})
        </Typography>
      )}

      {subjects.map((subject) => (
        <TextField
          label={getUserName(subject)}
          value={points[subject.id]}
          type="number"
          key={subject.id}
          fullWidth={true}
          onChange={(e) => handleChange(subject.id, parseInt(e.target.value))}
          size="small"
          sx={{ mt: 2 }}
        />
      ))}
    </>
  );
};
