import React from "react";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useTranslation } from "react-i18next";
import { CampaignGradingMode } from "../../types";

export interface Props {
  disabled?: boolean;
  selected?: CampaignGradingMode;
  onChange: (assignmentGradingMode: CampaignGradingMode) => void;
}

export const AssignmentGradingSelector = ({
  disabled = false,
  selected,
  onChange,
}: Props) => {
  const [t] = useTranslation();
  return (
    <FormControl size="small" fullWidth>
      <InputLabel id="assignment-grading-select-label">Assignment</InputLabel>
      <Select
        labelId="assignment-grading-select-label"
        id="assignment-grading-select"
        disabled={disabled}
        value={selected}
        label="Assignment"
        onChange={(e) => {
          // const target = e.target.value === "true";
          onChange(e.target.value as CampaignGradingMode);
        }}
      >
        <MenuItem key={0} value={CampaignGradingMode.FixedAuto}>
          {t("assignmentsGrading.fixedAutomatic")}
        </MenuItem>
        <MenuItem key={1} value={CampaignGradingMode.Manual}>
          {t("assignmentsGrading.manual")}
        </MenuItem>
      </Select>
    </FormControl>
  );
};
