import React from "react";
import {
  FormControl,
  InputLabel,
  ListItemAvatar,
  ListItemText,
  MenuItem,
  Select,
  SxProps,
} from "@mui/material";
import { User } from "../../types";
import { UserAvatar } from "./UserAvatar";
import { getUserName } from "../../DataTransform";

export interface Props {
  showAvatars?: boolean;
  label: string;
  users: User[];
  selected?: User;
  onChange: (user: User) => void;
  sx?: SxProps;
}

export const UserSelector = ({
  showAvatars,
  label,
  users,
  selected,
  onChange,
  sx,
}: Props) => {
  return (
    <FormControl style={{ minWidth: 190, maxWidth: 190 }} size="small" sx={sx}>
      <InputLabel id="user-select-label">{label}</InputLabel>
      <Select
        labelId="user-select-label"
        id="user-select"
        value={selected?.id || []}
        label={label}
        onChange={(e) => {
          const target = users.find((target) => target.id == e.target.value);
          target && onChange(target);
        }}
      >
        {users.map((user, index) => (
          <MenuItem key={index} value={user.id}>
            {showAvatars && (
              <>
                {" "}
                <ListItemAvatar>
                  <UserAvatar user={user} />
                </ListItemAvatar>
                <ListItemText primary={getUserName(user)} />
              </>
            )}
            {!showAvatars && getUserName(user)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
