import React from "react";
import { Delete, Edit, Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SxProps,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import PeopleIcon from "@mui/icons-material/People";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import GroupsIcon from "@mui/icons-material/Groups";
import MarkdownPreview from "@uiw/react-markdown-preview";
import rehypeSanitize from "rehype-sanitize";
import {
  FormItem,
  ItemType as QuestionTypeEnum,
  ItemRecipientType,
  ItemSubjectType,
  ItemVisibility,
} from "../../types";

export interface Props {
  editable?: boolean;
  formItem: FormItem;
  value?: number | string | null;
  onValueChange?: (answer: number | string) => void;
  onEditClick?: () => void;
  onDeleteClick?: () => void;
  sx?: SxProps;
}

export const QuestionView = ({
  editable = true,
  formItem: question,
  value,
  onValueChange,
  onEditClick,
  onDeleteClick,
  sx,
}: Props) => {
  const Interactions = () => (
    <Box
      sx={{
        display: "flex",
        mt: -1,
        mb: 0.5,
      }}
    >
      {onEditClick && (
        <Button
          disabled={!editable}
          variant="contained"
          sx={{ m: 2 }}
          onClick={() => onEditClick()}
          size="small"
        >
          Edit <Edit />
        </Button>
      )}
      {onDeleteClick && (
        <Button
          disabled={!editable}
          variant="contained"
          sx={{ m: 2, ml: 0 }}
          onClick={() => onDeleteClick()}
          size="small"
        >
          Delete <Delete />
        </Button>
      )}
    </Box>
  );

  if (question.itemType == QuestionTypeEnum.Instructions) {
    // TODO: Remove MarkdownPreview color overrides when auto-dark-mode is implemented

    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          mb: 3,
          ...sx,
        }}
      >
        <MarkdownPreview
          source={question.label}
          style={{ backgroundColor: "inherit", color: "white" }}
          rehypePlugins={[rehypeSanitize]}
        />
        <Box sx={{ mt: -1.5 }}>
          <Interactions />
        </Box>
      </Box>
    );
  }

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <Typography sx={{ flex: 1 }}>
          {question.label.split("\n").map((x, index, arr) => (
            <>
              {x}
              {index !== arr.length - 1 && <br />}
            </>
          ))}
        </Typography>
        {question.recipientType == ItemRecipientType.TeamMembers && (
          <Tooltip title="This question will be received by all team members">
            <GroupsIcon sx={{ ml: 1 }} />
          </Tooltip>
        )}
        {question.recipientType == ItemRecipientType.TeamLeaders && (
          <Tooltip title="This question will only be received by team leads">
            <ManageAccountsIcon sx={{ ml: 1 }} />
          </Tooltip>
        )}
        {question.visibility == ItemVisibility.Private && (
          <Tooltip title="This question's results will only be visible to instructors">
            <VisibilityOff sx={{ ml: 1 }} />
          </Tooltip>
        )}
        {question.visibility == ItemVisibility.Releasable && (
          <Tooltip title="This question's anonymized results are releasable to students">
            <Visibility sx={{ ml: 1 }} />
          </Tooltip>
        )}
        {question.subjectType == ItemSubjectType.PeerSelf && (
          <Tooltip title="This question is regarding self and peer performance during this course">
            <PeopleIcon sx={{ ml: 1 }} />
          </Tooltip>
        )}
        {question.subjectType == ItemSubjectType.PeerOnly && (
          <Tooltip title="This question is regarding peer performance during this course">
            <PeopleIcon sx={{ ml: 1 }} />
          </Tooltip>
        )}
        {question.subjectType == ItemSubjectType.General && (
          <Tooltip title="This question is reflecting on the course in general and results only visible to the instructor">
            <ContactSupportIcon sx={{ ml: 1 }} />
          </Tooltip>
        )}
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        {question.itemType == QuestionTypeEnum.Choice && (
          <>
            <FormControl
              fullWidth
              margin="normal"
              size="small"
              sx={{ mt: 1, mb: 2 }}
            >
              <InputLabel id={question.id}>Select an option</InputLabel>

              <Select
                labelId={question.id}
                id={`${question.id}-select`}
                value={value === undefined ? "" : value}
                label={"Select an option"}
                size="small"
                onChange={(e) =>
                  onValueChange && onValueChange(Number(e.target.value))
                }
              >
                {[...question.options]
                  .sort((a, b) => a.order - b.order)
                  .map((option, index) => (
                    <MenuItem key={index} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </>
        )}

        {question.itemType == QuestionTypeEnum.Text && (
          <TextField
            label={"Enter a response"}
            value={value === undefined || value === null ? "" : value}
            // InputLabelProps={{ shrink: true }}
            onChange={(e) => onValueChange && onValueChange(e.target.value)}
            fullWidth={true}
            size="small"
            sx={{ mt: 1, mb: 2 }}
          />
        )}

        {question.itemType == QuestionTypeEnum.Points && (
          <FormControl
            fullWidth
            margin="normal"
            size="small"
            sx={{ mt: 1, mb: 2 }}
          >
            Points per student (baseline): {question.scale}
          </FormControl>
        )}

        <Interactions />
      </Box>
    </>
  );
};
