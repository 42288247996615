import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import AccountCircle from "@mui/icons-material/AccountCircle";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import {
  Breadcrumbs,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Link as UILink,
} from "@mui/material";
import { Campaign, DynamicForm, Group } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ModalProgress } from "../Components/ModalProgress";

export interface Props {
  actionComponent?: React.ReactNode;
  breadcrumbs?: Breadcrumb[];
  loading?: boolean;
  studentVariant?: boolean;
  children: React.ReactNode;
  window?: () => Window;
}

export interface Breadcrumb {
  title: string;
  link?: string;
}

const drawerWidth = 240;

function Logo() {
  // TODO: Fix chart themes so we can restore auto dark mode
  // const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const prefersDarkMode = true;

  return (
    <Box sx={{ ml: "auto", mr: "auto" }}>
      <img
        src={prefersDarkMode ? "/logo-dark.svg" : "/logo-light.svg"}
        width="170"
      />
    </Box>
  );
}

function InstructorDrawerContents() {
  const { t } = useTranslation();
  return (
    <List>
      <ListItem>
        <Logo />
      </ListItem>
      <ListItem disablePadding>
        <ListItemButton
          component={Link}
          to="/campaigns"
          selected={window.location.pathname.startsWith("/campaigns")}
        >
          <ListItemIcon>
            <Campaign />
          </ListItemIcon>
          <ListItemText primary={t("navigation.campaigns")} />
        </ListItemButton>
      </ListItem>
      <ListItem disablePadding>
        <ListItemButton
          component={Link}
          to="/templates"
          selected={window.location.pathname.startsWith("/templates")}
        >
          <ListItemIcon>
            <DynamicForm />
          </ListItemIcon>
          <ListItemText primary={t("navigation.templates")} />
        </ListItemButton>
      </ListItem>
      <ListItem disablePadding>
        <ListItemButton
          component={Link}
          to="/teams"
          selected={"/teams" === window.location.pathname}
        >
          <ListItemIcon>
            <Group />
          </ListItemIcon>
          <ListItemText primary={t("navigation.teams")} />
        </ListItemButton>
      </ListItem>
    </List>
  );
}

function StudentDrawerContents() {
  const { t } = useTranslation();
  return (
    <List>
      <ListItem>
        <Logo />
      </ListItem>
      <ListItem disablePadding>
        <ListItemButton
          component={Link}
          to="/campaigns"
          selected={window.location.pathname.startsWith("/campaigns")}
        >
          <ListItemIcon>
            <Campaign />
          </ListItemIcon>
          <ListItemText primary={t("navigation.campaigns")} />
        </ListItemButton>
      </ListItem>
    </List>
  );
}

function getTitle(crumbs: Breadcrumb[]) {
  const pageTitle = crumbs[crumbs.length - 1].title || "";
  const title = "Feedback Loop";
  if (pageTitle) {
    return `${title} > ${pageTitle}`;
  }
  return title;
}

export function Layout({
  actionComponent = undefined,
  breadcrumbs,
  children,
  studentVariant = false,
  loading = false,
  window,
}: Props) {
  const [auth] = React.useState(false);
  const [drawer, setDrawer] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  React.useEffect(() => {
    document.title = getTitle(breadcrumbs || []);
  }, [breadcrumbs]);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDrawerToggle = () => {
    setDrawer(!drawer);
  };

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }}>
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
      >
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2, display: { sm: "none" } }}
            onClick={() => setDrawer(true)}
          >
            <MenuIcon />
          </IconButton>

          {breadcrumbs?.length && (
            <Breadcrumbs sx={{ flexGrow: 1, ml: "20px", color: "white" }}>
              {breadcrumbs.map(({ link, title }, index) => (
                <UILink
                  key={index}
                  component={Link}
                  underline="hover"
                  color="inherit"
                  to={link || "#"}
                >
                  {title}
                </UILink>
              ))}
            </Breadcrumbs>
          )}

          {actionComponent}

          {auth && (
            <div>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem onClick={handleClose}>Profile</MenuItem>
                <MenuItem onClick={handleClose}>My account</MenuItem>
              </Menu>
            </div>
          )}
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={drawer}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {studentVariant ? (
            <StudentDrawerContents />
          ) : (
            <InstructorDrawerContents />
          )}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          {studentVariant ? (
            <StudentDrawerContents />
          ) : (
            <InstructorDrawerContents />
          )}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        <Toolbar />
        {loading && <ModalProgress />}
        {children}
      </Box>
    </Box>
  );
}
