import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import {
  Analysis,
  StudentAverageAnalysis,
  FormItem,
  ItemType,
  Response,
  ResponseSubject,
  Team,
  TeamAverageAnalysis,
  Template,
  User,
  GenericAverageAnalysis,
  ResponseSubjectType,
  AggregateComments,
  PointRow,
  Answer,
  ItemTypeLabels,
} from "../../types";
import { Box, Typography } from "@mui/material";
import { Warning } from "@mui/icons-material";
import {
  VictoryAxis,
  VictoryBar,
  VictoryChart,
  VictoryLabel,
  VictoryLegend,
  VictoryScatter,
  VictoryTheme,
  VictoryTooltip,
} from "victory";
import { DownloadLink } from "../DownloadLink";

import { useResizeDetector } from "react-resize-detector";
import { getUserName } from "../../DataTransform";
import FeedbackLoopColors from "./Colors";
import {
  GroupSelfPeerColumns,
  IndividualSelfPeerColumns,
  IndividualSelfPeerOutlierColumns,
  TeamSelfPeerOutlierColumns,
  GroupColumns,
  IndividualColumns,
  IndividualOutlierColumns,
  TeamOutlierColumns,
  IndividualTextColumns,
  GroupTextColumns,
  IndividualPointColumns,
  GroupPointColumns,
  AverageSelfPeerColumns,
  AverageColumns,
  IndividualGivenColumns,
  GroupGivenColumns,
  AverageGivenColumns,
} from "./Columns";
import isCompatible from "../SubjectType/compatibility";

export function getScore(
  subjectResponses: ResponseSubject[],
  question: FormItem,
) {
  if (question.itemType == ItemType.Choice) {
    // TODO: refactor to avoid coercing types
    const unfilteredValues = subjectResponses.map(
      // (subjectResponse) => subjectResponse.answers[question.id]
      (subjectResponse) => {
        const answer = subjectResponse.answers.find(
          (answer) => answer.itemId === question.id,
        );
        return answer ? answer.value : undefined;
      },
    ) as unknown as number[];
    const values = unfilteredValues.filter((value) => !isNaN(value));

    if (!values.length) {
      return undefined;
    }

    const total = values.reduce((a, b) => a + b);
    return total / values.length;
  }
  return 0;
}

export function getAnalysis(
  responses: Response[],
  questions: FormItem[],
  users: User[],
  teams: Team[],
): Analysis[] {
  const analyses: Analysis[] = [];

  const otherResponseSubjects: Record<string, ResponseSubject[]> = {};

  // First, group responseSubjects together for generating 'others' score later
  responses.forEach((response) => {
    response.responseSubjects
      .filter(
        (rs) =>
          rs.subjectUserId !== response.authorId ||
          rs.subjectType !== ResponseSubjectType.Peer,
      )
      .forEach((rs) => {
        const subjectId = rs.subjectUserId || response.authorId;
        if (otherResponseSubjects[subjectId] === undefined) {
          otherResponseSubjects[subjectId] = [];
        }
        otherResponseSubjects[subjectId].push(rs);
      });
  });

  // Start gathering per-question self and others scores
  responses
    .filter(
      (response) =>
        users.find((user) => user.id === response.authorId) !== undefined,
    )
    .forEach((response) => {
      const team = teams.find((team) => team.id === response.groupId);
      questions
        .filter((question) => question.itemType === ItemType.Choice)
        .forEach((question) => {
          const selfResponses = response.responseSubjects.filter(
            (subjectResponse) =>
              subjectResponse.subjectUserId === response.authorId,
          );
          const givenResponses = response.responseSubjects.filter(
            (subjectResponse) =>
              subjectResponse.subjectUserId !== response.authorId,
          );
          const user = users.find((target) => target.id === response.authorId)!;
          const given = getScore(givenResponses, question);
          const self = getScore(selfResponses, question);
          const selfTimes = selfResponses
            .filter((sr) => sr.completed)
            .map((rs) =>
              rs.answers
                .map((ans) => new Date(ans.modified))
                .reduce((prev, current) => (prev > current ? prev : current)),
            );
          const otherTimes = (otherResponseSubjects[response.authorId] || [])
            .filter((sr) => sr.completed)
            .map((rs) =>
              rs.answers
                .map((ans) => new Date(ans.modified))
                .reduce((prev, current) => (prev > current ? prev : current)),
            );
          const maxStamp = [...selfTimes, ...otherTimes].reduce(
            (prev, current) =>
              prev !== undefined && prev > current ? prev : current,
            undefined as Date | undefined,
          );

          const others = getScore(
            otherResponseSubjects[response.authorId] || [],
            question,
          );
          const gap =
            others !== undefined && self !== undefined
              ? others - self
              : undefined;
          analyses.push({
            question,
            self,
            others,
            gap,
            given,
            user,
            team,
            label: question.label,
            timestamp: maxStamp,
          });
        });
    });

  //==========
  // ADDED on 1/24/24 by DE for 558-team-lead-survey
  // augment analyses list with subjects who aren't authors
  //==========
  const responseAuthorIds = responses.map((response) => response.authorId);
  const responseSubjectIds = responses.flatMap((response) =>
    response.responseSubjects
      .filter((rs) => rs.subjectUserId !== null)
      .map((responseSubject) => responseSubject.subjectUserId),
  );
  const nonAuthorIds = responseSubjectIds.filter(
    (rsId) => rsId !== null && !responseAuthorIds.includes(rsId),
  );

  nonAuthorIds.forEach((rsId) => {
    const team = teams.find((team) => team.userIds.includes(rsId!));
    questions
      .filter((question) => question.itemType === ItemType.Choice)
      .forEach((question) => {
        const user = users.find((target) => target.id === rsId)!;
        const given = undefined;
        const self = undefined;
        const otherTimes = (otherResponseSubjects[rsId!] || [])
          .filter((sr) => sr.completed)
          .map((rs) =>
            rs.answers
              .map((ans) => new Date(ans.modified))
              .reduce((prev, current) => (prev > current ? prev : current)),
          );
        const maxStamp = [...otherTimes].reduce(
          (prev, current) =>
            prev !== undefined && prev > current ? prev : current,
          undefined as Date | undefined,
        );

        const others = getScore(otherResponseSubjects[rsId!] || [], question);
        const gap = undefined;
        analyses.push({
          question,
          self,
          others,
          gap,
          given,
          user,
          team,
          label: question.label,
          timestamp: maxStamp,
        });
      });
  });

  return analyses;
}

// Create a function that takes a list of responses, questions, users and teams and returns a list of objects with the following properties:
// - question: the question label
// - comments: a list of objects with the following properties:
//   - respondent: the user who wrote the comment
//   - recipient: the user who the comment is about
//   - comment: the comment text
//   - timestamp: the timestamp of the comment
export function getComments(
  responses: Response[],
  questions: FormItem[],
  users: User[],
  teams: Team[],
): AggregateComments[] {
  const comments: AggregateComments[] = [];

  // Start gathering per-question self and others scores
  questions
    .filter((question) => question.itemType === ItemType.Text)
    .forEach((question) => {
      const comment: AggregateComments = {
        question,
        comments: [],
      };

      responses.forEach((response, rid) => {
        const team = teams.find((team) => team.id === response.groupId);
        const sender = users.find((target) => target.id === response.authorId)!;

        response.responseSubjects.forEach((rs, rsid) => {
          const answer = rs.answers.find(
            (answer) => answer.itemId === question.id,
          );
          const recipient = users.find(
            (target) => target.id === rs.subjectUserId,
          )!;

          if (answer) {
            comment.comments.push({
              id: `${rid}-${rsid}-${question.id}`,
              comment: answer.text!,
              recipient,
              respondent: sender,
              team,
              timestamp: new Date(answer.modified),
            });
          }
        });
      });

      comments.push(comment);
    });

  return comments;
}

export function getPointRows(
  responses: Response[],
  questions: FormItem[],
  users: User[],
  teams: Team[],
): PointRow[] {
  const rows: PointRow[] = [];

  // Start gathering per-question self and others scores
  responses.forEach((response) => {
    const team = teams.find((team) => team.id === response.groupId);
    questions
      .filter((question) => question.itemType === ItemType.Points)
      .forEach((question) => {
        const sender = users.find((target) => target.id === response.authorId)!;

        response.responseSubjects.forEach((rs) => {
          const answer = rs.answers.find(
            (answer) => answer.itemId === question.id,
          );
          const recipient = users.find(
            (target) => target.id === rs.subjectUserId,
          )!;

          if (answer) {
            rows.push({
              question,
              value: answer.value!,
              user: recipient,
              sender,
              team,
              label: question.label,
              timestamp: new Date(answer.modified),
            });
          }
        });
      });
  });
  return rows;
}

// https://stackoverflow.com/questions/14964035/how-to-export-javascript-array-info-to-csv-on-client-side
function arrayToCsv(data: string[][], header: string) {
  const rows = [
    header,
    ...data.map(
      (row) =>
        row
          .map(String) // convert every value to String
          .map((v) => v.replaceAll('"', '""')) // escape double colons
          .map((v) => `"${v}"`) // quote it
          .join(","), // comma-separated
    ),
  ];

  return rows.join("\r\n"); // rows starting on new lines
}

// Flatten a list of Responses into a list of objects with an Answer,
// ResponseSubject, and Response
function flattenResponses(responses: Response[]) {
  return responses.flatMap((response) =>
    response.responseSubjects.flatMap((responseSubject) =>
      responseSubject.answers.map((answer) => ({
        answer,
        responseSubject,
        response,
      })),
    ),
  );
}

// Given a flattened list of answers, responseSubjects, and responses,
// create a list of objects with the following properties:
// - question: the question label
// - recipient: the recipient's name (or SELF if the respondent is the recipient)
// - respondent: the respondent's name
// - response: the response text
// - timestamp: the timestamp of the response
function flattenAnswers(
  flattenedResponses: {
    answer: Answer;
    responseSubject: ResponseSubject;
    response: Response;
  }[],
  users: User[],
  teams: Team[],
  questions: FormItem[],
) {
  // filter out responses that don't have a corresponding question
  flattenedResponses = flattenedResponses.filter((flattenedResponse) =>
    questions.find((q) => q.id === flattenedResponse.answer.itemId),
  );

  // filter out responses that don't have a corresponding subject
  flattenedResponses = flattenedResponses.filter(
    (flattenedResponse) =>
      flattenedResponse.responseSubject.subjectUserId === null ||
      users.find(
        (u) => u.id === flattenedResponse.responseSubject.subjectUserId,
      ),
  );

  return flattenedResponses.map((flattenedResponse) => {
    const { answer, responseSubject, response } = flattenedResponse;
    const question = questions.find((q) => q.id === answer.itemId);
    const respondent = users.find((u) => u.id === response.authorId);
    const recipient = users.find((u) => u.id === responseSubject.subjectUserId);
    const team = teams.find((t) => t.id === response.groupId);

    return {
      question: question ? question.label : "",
      recipient: recipient
        ? `${recipient.firstName} ${recipient.lastName}`
        : "",
      respondent:
        respondent === recipient
          ? "SELF"
          : respondent
            ? `${respondent.firstName} ${respondent.lastName}`
            : "",
      response: answer.text || answer.value || "N/A",
      timestamp: new Date(answer.modified),
      team: team ? team.name : "",
      questionTypeLabel: question ? ItemTypeLabels[question.itemType] : "",
    };
  });
}

function countWords(str: string) {
  return str.trim().split(/\s+/).length;
}

// Given the output of flattenAnswers, create an array of arrays of strings
// that can be used to create a CSV
function flattenAnswersToCsvData(
  flattenedAnswers: ReturnType<typeof flattenAnswers>,
) {
  return flattenedAnswers.map((flattenedAnswer) => [
    flattenedAnswer.question,
    flattenedAnswer.recipient,
    flattenedAnswer.respondent,
    flattenedAnswer.response.toString(),
    flattenedAnswer.questionTypeLabel === "Text"
      ? countWords(flattenedAnswer.response.toString()).toString()
      : "",
    flattenedAnswer.timestamp.toLocaleString(),
    flattenedAnswer.team,
    flattenedAnswer.questionTypeLabel,
  ]);
}

function analysesToArray(
  analyses: Analysis[],
  aggregates: AggregateComments[],
  pointRows: PointRow[],
  averages: StudentAverageAnalysis[],
  reverse: boolean,
): string[][] {
  const output = [
    ...averages.map((average) => [
      "AVERAGE",
      "",
      getUserName(average.user),
      average.user.email,
      "",
      ...(reverse
        ? [average.given?.toLocaleString() ?? ""]
        : [
            average.self?.toLocaleString() ?? "",
            average.others?.toLocaleString() ?? "",
            average.gap?.toLocaleString() ?? "",
          ]),
      "",
      "",
      "NA",
    ]),
    ...pointRows.map((pointRow) => [
      pointRow.question.category,
      pointRow.question.label,
      getUserName(pointRow.user),
      pointRow.user.email,
      pointRow.team ? pointRow.team.name : "NA",
      "",
      "",
      "",
      pointRow.value.toLocaleString(),
      getUserName(pointRow.sender),
      pointRow.timestamp ? pointRow.timestamp.toLocaleString() : "NA",
    ]),
  ];

  return output;
}

function analysesToObjectArray(
  analyses: Analysis[],
  aggregates: AggregateComments[],
  pointRows: PointRow[],
  sections: string[],
) {
  return sections.map((section, id) => ({
    id,
    section,
    analyses: analyses
      .filter((analysis) => analysis.question.category === section)
      .map((analysis, id) => ({
        id,
        section: analysis.question.category,
        question: analysis.question.label,
        user: getUserName(analysis.user),
        given: analysis.given,
        self: analysis.self,
        peers: analysis.others,
        gap: analysis.gap,
      })),
    aggregates: aggregates
      .filter((aggregate) => aggregate.question.category === section)
      .map((aggregate, id) => ({
        id,
        section: aggregate.question.category,
        question: aggregate.question.label,
        comments: aggregate.comments.map((comment) => ({
          ...comment,
          recipient: getUserName(comment.recipient),
          respondent: getUserName(comment.respondent),
        })),
      })),
    pointRows: pointRows
      .filter((pointRow) => pointRow.question.category === section)
      .map((pointRow, id) => ({
        id,
        section: pointRow.question.category,
        question: pointRow.question.label,
        user: getUserName(pointRow.user),
        sender: getUserName(pointRow.sender),
        value: pointRow.value,
      })),
  }));
}

function questionsByStudent(users: User[], analyses: Analysis[]) {
  return users
    .map((user) => ({
      user,
      questions: analyses.filter((analysis) => analysis.user == user),
    }))
    .sort((itemA, itemB) =>
      itemA.user.firstName > itemB.user.firstName ? 1 : -1,
    );
}

function questionsByTeam(teams: Team[], analyses: Analysis[]) {
  return teams
    .map((team) => ({
      team,
      questions: analyses.filter((analysis) =>
        team.userIds.find((userId) => analysis.user.id == userId),
      ),
    }))
    .sort((itemA, itemB) => (itemA.team.name > itemB.team.name ? 1 : -1));
}

function averagesByStudent(users: User[], analyses: Analysis[]) {
  function averageForStudent(
    student: User,
    sectionQuestions: Analysis[],
  ): StudentAverageAnalysis {
    const selfScores = sectionQuestions
      .map((question) => question.self)
      .filter((score) => score !== undefined) as number[];
    const self = selfScores.reduce((a, b) => a! + b!, 0) / selfScores.length;
    const otherScores = sectionQuestions
      .map((question) => question.others)
      .filter((score) => score !== undefined) as number[];
    const others =
      otherScores.reduce((a, b) => a! + b!, 0) / otherScores.length;
    const gap = others - self;
    const givenScores = sectionQuestions
      .map((question) => question.given)
      .filter((score) => score !== undefined) as number[];
    const given = givenScores.reduce((a, b) => a! + b!, 0) / givenScores.length;
    const outlier = self <= 4.0 || others <= 4.0 || gap <= -1.5 || gap >= 1.5;
    return {
      self,
      others,
      gap,
      given,
      user: student,
      label: `${student.firstName} ${student.lastName}`,
      id: student.id,
      outlier,
    };
  }

  return [
    {
      user: analyses.length > 0 ? analyses[0].user : undefined,
      questions: questionsByStudent(users, analyses).map((analysis) =>
        averageForStudent(analysis.user, analysis.questions),
      ),
    },
  ];
}

function averagesByTeam(teams: Team[], analyses: Analysis[]) {
  function averageForTeam(
    team: Team,
    sectionQuestions: Analysis[],
  ): TeamAverageAnalysis {
    const selfScores = sectionQuestions
      .map((question) => question.self)
      .filter((score) => score !== undefined) as number[];
    const self = selfScores.reduce((a, b) => a! + b!, 0) / selfScores.length;
    const otherScores = sectionQuestions
      .map((question) => question.others)
      .filter((score) => score !== undefined) as number[];
    const others =
      otherScores.reduce((a, b) => a! + b!, 0) / otherScores.length;
    const gap = others - self;
    const givenScores = sectionQuestions
      .map((question) => question.given)
      .filter((score) => score !== undefined) as number[];
    const given = givenScores.reduce((a, b) => a! + b!, 0) / givenScores.length;
    const outlier = self <= 4.0 || others <= 4.0 || gap <= -1.5 || gap >= 1.5;
    return {
      self,
      others,
      gap,
      given,
      team,
      label: team.name,
      id: team.id,
      outlier,
    };
  }

  return [
    {
      user: analyses.length > 0 ? analyses[0].user : undefined,
      questions: questionsByTeam(teams, analyses).map((analysis) =>
        averageForTeam(analysis.team, analysis.questions),
      ),
    },
  ];
}

export interface Props {
  responses: Response[];
  reverse: boolean;
  subjectType: ResponseSubjectType;
  showStudents?: boolean;
  showQuestions?: boolean;
  teams: Team[];
  template: Template;
  title: string;
  users: User[];
  user?: User;
  onTeamClick?: (id: string) => void;
  onUserClick?: (id: string) => void;
}

const GridHeaderFactory = (
  section: string,
  color?: string,
  icon?: JSX.Element,
) => {
  const GridHeader = () => (
    <Typography
      variant="subtitle1"
      sx={{
        pl: 1,
        pt: 0.5,
        pb: 0.5,
        backgroundColor: color ? color : "#222",
      }}
    >
      {section} {icon}
    </Typography>
  );
  return GridHeader;
};

export const AnalysisView = ({
  responses,
  reverse,
  subjectType,
  showQuestions = false,
  showStudents = false,
  teams,
  template,
  title,
  users,
  user,
  onTeamClick,
  onUserClick,
}: Props) => {
  const nonEmptyResponses = responses.filter((response) => response.submitted);

  const responseUsers: User[] = Array.from(
    new Set(
      users.length > 0
        ? nonEmptyResponses.flatMap((response) => [
            users.find((user) => user.id === response.authorId) as User,
            ...response.responseSubjects.map(
              (rs) =>
                users.find((user) => user.id === rs.subjectUserId) as User,
            ),
          ])
        : [],
    ),
  ).filter((user) => user !== undefined);

  const questions = template.items.filter(
    //(item) => item.subjectType === (subjectType as unknown as ItemSubjectType)
    (item) => isCompatible(subjectType, item.subjectType),
  );
  const analyses = (
    users.length > 0 ? getAnalysis(responses, questions, users, teams) : []
  ).filter((analysis) => user === undefined || analysis.user === user);
  const aggregates =
    users.length > 0
      ? getComments(responses, questions, users, teams).map((analysis) => ({
          ...analysis,
          comments: analysis.comments.filter(
            (comment) => user === undefined || comment.recipient.id === user.id,
          ),
        }))
      : [];
  const pointRows = (
    users.length > 0 ? getPointRows(responses, questions, users, teams) : []
  ).filter((analysis) => user === undefined || analysis.user === user);

  const avgsByStudent = averagesByStudent(responseUsers, analyses);
  const analysesByStudent = showStudents
    ? !showQuestions
      ? avgsByStudent
      : questionsByStudent(responseUsers, analyses).filter(
          (analysis) => analysis.user.id === user?.id,
        )
    : averagesByTeam(teams, analyses);
  const filteredAverages = user
    ? avgsByStudent[0].questions.filter((a) => a.user.id === user.id)
    : avgsByStudent[0].questions;
  const averageAnalyses = filteredAverages.map((analysis) => ({
    ...analysis,
    user: getUserName(analysis.user),
  }));

  // console.log("analysesByStudent", analysesByStudent);

  const outlierFilter = (
    question: StudentAverageAnalysis | TeamAverageAnalysis,
  ) => question.outlier;

  const outliers = showStudents
    ? !showQuestions
      ? (analysesByStudent[0].questions as StudentAverageAnalysis[]).filter(
          outlierFilter,
        )
      : []
    : (analysesByStudent[0].questions as TeamAverageAnalysis[]).filter(
        outlierFilter,
      );

  const sections = questions
    .map((question) => question.category)
    .filter((category, index, array) => array.indexOf(category) === index);

  const groupCount = showStudents
    ? !showQuestions
      ? responseUsers.length
      : questions.filter((question) => question.itemType === ItemType.Choice)
          .length
    : teams.length;

  const id = "analysis-view";
  const { width: chartWidth, ref: chartRef } =
    useResizeDetector<HTMLDivElement>();
  const safeChartWidth = chartWidth ? chartWidth : 800;
  // const legendItems = [{name: 'wee'},{name: 'wee'},{name: 'wee'},{name: 'wee'},{name: 'wee'},{name: 'wee'},{name: 'wee'},{name: 'wee'},{name: 'wee'},{name: 'wee'},{name: 'wee'},{name: 'wee'},{name: 'wee'},{name: 'wee'},{name: 'wee'},{name: 'wee'},{name: 'wee'},{name: 'wee'},{name: 'wee'},{name: 'wee'},{name: 'wee'},{name: 'wee'},{name: 'wee'},{name: 'wee'},{name: 'wee'},{name: 'wee'},{name: 'wee'},{name: 'wee11'},];
  const legendItems = showStudents
    ? responseUsers
        .sort((userA, userB) => (userA.firstName > userB.firstName ? 1 : -1))
        .map((user) => ({
          name:
            user.firstName.length < 12
              ? user.firstName
              : user.firstName.substring(0, 10) + "...",
        }))
    : teams
        .sort((teamA, teamB) => (teamA.name > teamB.name ? 1 : -1))
        .map((team) => ({
          name:
            team.name.length < 12
              ? team.name
              : team.name.substring(0, 10) + "...",
        }));
  const legendGutter = 75;
  const legendPadding = 100;
  const itemsPerRow = Math.round(
    chartWidth ? (chartWidth - legendPadding) / (legendGutter * 2) : 10,
  );
  const legendRowGutter = 20;
  const legendHeight =
    Math.ceil(legendItems.length / itemsPerRow) * (14 + legendRowGutter * 2);
  const legendOffset =
    legendItems.length < itemsPerRow
      ? safeChartWidth / 2 - legendItems.length * legendGutter
      : 0;
  const groupOffset = 20;
  const chartHeight = groupCount * (groupOffset + 4) * (1 + 2) + 150;
  const safeChartHeight = chartHeight && chartHeight >= 200 ? chartHeight : 200;
  const barLabel = subjectType === ResponseSubjectType.Peer ? "PEERS" : "VALUE";

  function friendlyLabel(text: string) {
    const lines = divideIntoLines(text, 20);
    if (lines.length > 2) lines[1] = lines[1] + "...";
    return lines.slice(0, 2);
  }

  function prependWarning(text: string, outlier: boolean | undefined) {
    return outlier ? `⚠️ ${text}` : text;
  }

  function divideIntoLines(text: string, targetLength: number) {
    const words = text.split(" ");
    const lines = [];
    let line = "";

    for (let i = 0; i < words.length; i++) {
      if (words[i].length > targetLength) {
        // split the word into chunks that fit the target length
        const chunks = chunkString(words[i], targetLength);
        for (let j = 0; j < chunks.length; j++) {
          lines.push(chunks[j]);
        }
      } else if (line.length + words[i].length + 1 <= targetLength) {
        line += words[i] + " ";
      } else {
        lines.push(line.trim());
        line = "";
        i--;
      }
    }

    lines.push(line.trim());
    return lines;
  }

  function chunkString(str: string, length: number) {
    const chunks = [];
    for (let i = 0; i < str.length; i += length) {
      chunks.push(str.substring(i, i + length) + "-");
    }
    chunks[chunks.length - 1] = chunks[chunks.length - 1].slice(0, -1);
    return chunks;
  }

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          mb: 3,
        }}
      >
        <DownloadLink
          filename={`${title}-summary.csv`}
          mimeType="text/csv"
          label="Download Summary Excel"
          contents={arrayToCsv(
            analysesToArray(
              analyses,
              aggregates,
              pointRows,
              filteredAverages,
              reverse,
            ),
            reverse
              ? "Category,Question,Student,Email,Team,Given,Response,Sender,Timestamp"
              : "Category,Question,Student,Email,Team,Self,Peers,Gap,Response,Sender,Timestamp",
          )}
          buttonProps={{
            variant: "contained",
            sx: { mr: 2 },
          }}
        />
        <DownloadLink
          filename={`${title}-raw.csv`}
          mimeType="text/csv"
          label="Download Raw Excel"
          contents={arrayToCsv(
            flattenAnswersToCsvData(
              flattenAnswers(
                flattenResponses(responses),
                users,
                teams,
                questions,
              ),
            ),
            "Question,Recipient,Respondent,Response,Word Count,Timestamp,Team,Question Type",
          )}
          buttonProps={{
            variant: "contained",
          }}
        />
      </Box>
      <div
        style={{
          height: safeChartHeight,
          ...(reverse ? { display: "none" } : {}),
        }}
        ref={chartRef}
      >
        {analysesByStudent.length > 0 && (
          <VictoryChart
            horizontal
            theme={VictoryTheme.material}
            padding={{ bottom: 50, left: 100, right: 0 }}
            height={safeChartHeight}
            width={safeChartWidth}
            domain={{ x: [0.5, groupCount + 0.5], y: [0.5, 5.5] }}
          >
            <VictoryAxis
              style={{
                axis: { stroke: "white" },
                axisLabel: { padding: 20 },
                grid: { stroke: "white" },
                ticks: { stroke: "white", size: 5 },
                tickLabels: { padding: 5, stroke: "white", fill: "white" },
              }}
              tickLabelComponent={
                <VictoryLabel
                  angle={-45}
                  textAnchor={"end"}
                  text={({ datum }) =>
                    analysesByStudent[0].questions[datum - 1]
                      ? prependWarning(
                          friendlyLabel(
                            analysesByStudent[0].questions[datum - 1].label,
                          ) as unknown as string,
                          analysesByStudent[0].questions[datum - 1].outlier,
                        )
                      : ""
                  }
                />
              }
            />

            <VictoryAxis
              dependentAxis
              label="Rating"
              style={{
                axis: { stroke: "white" },
                axisLabel: { padding: 30 },
                grid: { stroke: "white" },
                ticks: { stroke: "white", size: 5 },
                tickLabels: { padding: 5, stroke: "white", fill: "white" },
              }}
            />

            <VictoryBar
              colorScale={FeedbackLoopColors}
              events={[
                {
                  target: "data",
                  eventHandlers: {
                    onClick: () => {
                      return [
                        {
                          mutation: ({ index }) => {
                            if (!showQuestions) {
                              showStudents
                                ? onUserClick &&
                                  onUserClick(
                                    (
                                      analysesByStudent[0].questions[
                                        index
                                      ] as GenericAverageAnalysis
                                    ).id,
                                  )
                                : onTeamClick &&
                                  onTeamClick(
                                    (
                                      analysesByStudent[0].questions[
                                        index
                                      ] as GenericAverageAnalysis
                                    ).id,
                                  );
                            }

                            return {};
                          },
                        },
                      ];
                    },
                  },
                },
              ]}
              key={
                analysesByStudent[0].user
                  ? analysesByStudent[0].user.id
                  : "static"
              }
              labelComponent={
                <VictoryTooltip constrainToVisibleArea orientation={"top"} />
              }
              name="bar"
              data={analysesByStudent[0].questions.map((question) => ({
                x: question.label,
                y: question.others,
                label: `${
                  question.label
                } (${barLabel}: ${question.others?.toFixed(1)})`,
              }))}
              style={{
                data: {
                  stroke: "white",
                  strokeDasharray: showQuestions
                    ? 0
                    : ({ index }) =>
                        analysesByStudent[0].questions[index as number].outlier
                          ? 3
                          : 0,
                  strokeWidth: 2,
                  fill: showQuestions
                    ? FeedbackLoopColors[0]
                    : ({ index }) => FeedbackLoopColors[(index as number) % 5],
                },
              }}
            />

            <VictoryScatter
              key={
                analysesByStudent[0].user
                  ? analysesByStudent[0].user.id + "others"
                  : "staticothers"
              }
              style={{
                data: {
                  // fill: "white",
                  fill: showQuestions
                    ? "white"
                    : ({ index }) =>
                        analysesByStudent[0].questions[index as number].outlier
                          ? "yellow"
                          : "white",
                },
              }}
              size={6}
              labelComponent={
                <VictoryTooltip constrainToVisibleArea orientation={"top"} />
              }
              name="scatter"
              data={analysesByStudent[0].questions.map((question) => ({
                x: question.label,
                y: question.self,
                label: `${question.label} (SELF: ${question.self?.toFixed(1)})`,
                others: question.others,
              }))}
            />
          </VictoryChart>
        )}
      </div>

      {!showQuestions && (
        <div>
          <VictoryLegend
            x={legendPadding + legendOffset}
            // y={50}
            title=""
            height={legendHeight}
            width={chartWidth}
            centerTitle
            orientation="horizontal"
            gutter={legendGutter}
            rowGutter={legendRowGutter}
            style={{
              border: { stroke: "none", width: "100%" },
              title: { fontSize: 20, fill: "white" },
              labels: { fontSize: 14, fill: "white" },
            }}
            colorScale={FeedbackLoopColors}
            itemsPerRow={itemsPerRow}
            data={legendItems}
          />
        </div>
      )}

      <div id={id}>
        {outliers.length > 0 && !reverse && (
          <DataGrid
            autoHeight={true}
            initialState={{
              sorting: {
                sortModel: [{ field: "gap", sort: "desc" }],
              },
            }}
            hideFooter={true}
            rows={outliers as GenericAverageAnalysis[]}
            components={{
              Toolbar: GridHeaderFactory(
                "Outliers",
                "#522",
                <Warning color="warning" fontSize="inherit" />,
              ),
            }}
            sx={{
              backgroundColor: "black",
              mb: 1,
              cursor: "pointer",
            }}
            columns={
              showStudents
                ? subjectType === ResponseSubjectType.Peer
                  ? IndividualSelfPeerOutlierColumns
                  : IndividualOutlierColumns
                : subjectType === ResponseSubjectType.Peer
                  ? TeamSelfPeerOutlierColumns
                  : TeamOutlierColumns
            }
            pagination={undefined}
            onRowClick={(params) =>
              showStudents
                ? onUserClick && onUserClick(params.row.id)
                : onTeamClick && onTeamClick(params.row.id)
            }
          />
        )}

        <DataGrid
          autoHeight={true}
          initialState={{
            sorting: {
              sortModel: [{ field: "peers", sort: "desc" }],
            },
          }}
          hideFooter={true}
          rows={averageAnalyses}
          components={{
            Toolbar: GridHeaderFactory("Averages"),
          }}
          sx={{ backgroundColor: "black", mb: 1 }}
          columns={
            subjectType === ResponseSubjectType.Peer
              ? reverse
                ? AverageGivenColumns
                : AverageSelfPeerColumns
              : AverageColumns
          }
          pagination={undefined}
          getRowHeight={() => "auto"}
        />

        {analysesToObjectArray(analyses, aggregates, pointRows, sections).map(
          (section) => (
            <>
              <DataGrid
                autoHeight={true}
                key={section.id}
                initialState={{
                  sorting: {
                    sortModel: [{ field: "gap", sort: "desc" }],
                  },
                }}
                hideFooter={true}
                rows={section.analyses}
                components={{
                  Toolbar: GridHeaderFactory(section.section),
                }}
                sx={{ backgroundColor: "black", mb: 1 }}
                columns={
                  showQuestions
                    ? subjectType === ResponseSubjectType.Peer
                      ? reverse
                        ? IndividualGivenColumns
                        : IndividualSelfPeerColumns
                      : IndividualColumns
                    : subjectType === ResponseSubjectType.Peer
                      ? reverse
                        ? GroupGivenColumns
                        : GroupSelfPeerColumns
                      : GroupColumns
                }
                pagination={undefined}
                getRowHeight={() => "auto"}
              />

              {section.aggregates.map((aggregate) => (
                <DataGrid
                  autoHeight={true}
                  key={aggregate.id}
                  hideFooter={true}
                  rows={aggregate.comments}
                  components={{
                    Toolbar: GridHeaderFactory(
                      `${section.section} - ${aggregate.question}`,
                    ),
                  }}
                  sx={{ backgroundColor: "black", mb: 1 }}
                  columns={
                    showQuestions ? IndividualTextColumns : GroupTextColumns
                  }
                  pagination={undefined}
                  getRowHeight={() => "auto"}
                />
              ))}

              {section.pointRows.length > 0 && (
                <DataGrid
                  autoHeight={true}
                  key={section.id}
                  hideFooter={true}
                  rows={section.pointRows}
                  components={{
                    Toolbar: GridHeaderFactory(
                      `${section.section} (Points allocation)`,
                    ),
                  }}
                  sx={{ backgroundColor: "black", mb: 1 }}
                  columns={
                    showQuestions ? IndividualPointColumns : GroupPointColumns
                  }
                  pagination={undefined}
                  getRowHeight={() => "auto"}
                />
              )}
            </>
          ),
        )}
      </div>
    </>
  );
};
