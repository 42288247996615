import { Response, ResponseSubject, User } from "./types";

export function getUnique<T>(input: T[]): T[] {
  return Array.from(new Set<T>(input));
}

export function getCampaignProgress(responses: Response[]) {
  return {
    completed: responses.filter((r) => r.submitted).length,
    total: responses.length,
  };
}

export function getUserName(user: User) {
  if (!user) {
    return "[Course]";
  }
  return `${user.firstName} ${user.lastName}`;
}

export function authorFirst(
  subjectResponses: ResponseSubject[],
  authorId: string,
): ResponseSubject[] {
  return [...subjectResponses].sort(
    (a, b) =>
      Number(b.subjectUserId === authorId) -
      Number(a.subjectUserId === authorId),
  );
}
