import React from "react";
import { Box, CircularProgress, Typography } from "@mui/material";
import { getCampaignProgress } from "../../DataTransform";
import { Response } from "../../types";

export interface Props {
  responses?: Response[];
  submitted?: number;
  total?: number;
}

function decimalToHex(d: number, padding: number) {
  d = Math.floor(between(d, 0, 255));
  let hex = Number(d).toString(16);
  padding =
    typeof padding === "undefined" || padding === null
      ? (padding = 2)
      : padding;

  while (hex.length < padding) {
    hex = "0" + hex;
  }

  return hex;
}

function between(num: number, min: number, max: number) {
  return Math.max(min, Math.min(num, max));
}
export function getColor(proportion: number) {
  let red = 0;
  let green = 0;
  const blue = 0;
  if (proportion < 0.5) {
    red = 255;
    green = proportion * 2 * 256;
  } else {
    red = (1 - proportion) * 2 * 256;
    green = 255;
  }
  return `#${decimalToHex(red, 2)}${decimalToHex(green, 2)}${decimalToHex(
    blue,
    2,
  )}`;
}

export const CampaignProgress = ({
  responses = [],
  submitted,
  total,
}: Props) => {
  const progress =
    submitted !== undefined && total !== undefined
      ? { completed: submitted, total }
      : getCampaignProgress(responses);
  const proportion = progress.completed / progress.total;

  return (
    <Box sx={{ display: "flex", justifyContent: "left" }}>
      <CircularProgress
        variant="determinate"
        value={100 * proportion}
        sx={{ mr: 1, color: getColor(proportion) }}
      />
      <Typography sx={{ lineHeight: "40px", verticalAlign: "middle" }}>
        {progress.completed} / {progress.total} Completed
      </Typography>
    </Box>
  );
};
