import React from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SxProps,
} from "@mui/material";
import { Section } from "../../types";

export interface Props {
  sections: Section[];
  selected?: Section;
  onChange: (section?: Section) => void;
  sx?: SxProps;
}

export const SectionSelector = ({
  sections,
  selected,
  onChange,
  sx,
}: Props) => {
  return (
    <FormControl size="small" sx={sx}>
      <InputLabel id="section-select-label">Section</InputLabel>
      <Select
        labelId="section-select-label"
        id="section-select"
        value={selected ? selected.id : "ALL"}
        label="Section"
        onChange={(e) => {
          const target = sections.find((target) => target.id == e.target.value);
          onChange(target);
        }}
      >
        <MenuItem key={-1} value={"ALL"}>
          All sections
        </MenuItem>
        {sections.map((section, index) => (
          <MenuItem key={index} value={section.id}>
            {section.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
