import React from "react";
import {
  GridCellParams,
  GridRenderCellParams,
  GridValueFormatterParams,
} from "@mui/x-data-grid";
import { Analysis } from "../../types";
import { Typography } from "@mui/material";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";
import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";

function LowerIcon() {
  return <ArrowCircleDownIcon sx={{ color: "red", width: "20px", ml: -0.5 }} />;
}

function HigherIcon() {
  return <ArrowCircleUpIcon sx={{ color: "green", width: "20px", ml: -0.5 }} />;
}

const safeFixed = (value: number) =>
  Number.isFinite(value) ? value.toFixed(2) : "--";
const safeFixedPercent = (value: number) =>
  Number.isFinite(value) ? (value * 100).toFixed(0) : "--";

export const IndividualSelfPeerColumns = [
  {
    headerName: "Question",
    field: "question",
    flex: 400,
  },
  {
    headerName: "Self",
    field: "self",
    flex: 66,
    valueFormatter: ({ value }: GridValueFormatterParams<number>) =>
      safeFixed(value),
  },
  {
    headerName: "Peers",
    field: "peers",
    flex: 66,
    valueFormatter: ({ value }: GridValueFormatterParams<number>) =>
      safeFixed(value),
  },
  {
    headerName: "Gap",
    field: "gap",
    flex: 66,
    valueFormatter: ({ value }: GridValueFormatterParams<number>) =>
      safeFixed(value),
    cellClassName: ({ value }: GridCellParams<number>) =>
      value && value > 0
        ? "alignment-positive"
        : value && value < 0
          ? "alignment-negative"
          : "",
  },
];

export const IndividualGivenColumns = [
  {
    headerName: "Question",
    field: "question",
    flex: 400,
  },
  {
    headerName: "Given",
    field: "given",
    flex: 66,
    valueFormatter: ({ value }: GridValueFormatterParams<number>) =>
      safeFixed(value),
  },
];

export const IndividualSelfPeerOutlierColumns = [
  {
    headerName: "Name",
    field: "label",
    flex: 400,
  },
  {
    headerName: "Self",
    field: "self",
    flex: 50,
    minWidth: 75,
    valueFormatter: ({ value }: GridValueFormatterParams<number>) =>
      safeFixed(value),
    renderCell: (analysis: GridRenderCellParams<Analysis>) => (
      <>
        <Typography padding={1}>{safeFixed(analysis.row.self)}</Typography>
        {Number.isFinite(analysis.row.self) && analysis.row.self <= 4.0 && (
          <LowerIcon />
        )}
      </>
    ),
  },
  {
    headerName: "Peers",
    field: "others",
    flex: 50,
    minWidth: 75,
    valueFormatter: ({ value }: GridValueFormatterParams<number>) =>
      safeFixed(value),
    renderCell: (analysis: GridRenderCellParams<Analysis>) => (
      <>
        <Typography padding={1}>{safeFixed(analysis.row.others)}</Typography>
        {Number.isFinite(analysis.row.others) && analysis.row.others <= 4.0 && (
          <LowerIcon />
        )}
      </>
    ),
  },
  {
    headerName: "Gap",
    field: "gap",
    flex: 50,
    minWidth: 75,
    valueFormatter: ({ value }: GridValueFormatterParams<number>) =>
      safeFixed(value),
    renderCell: (analysis: GridRenderCellParams<Analysis>) => (
      <>
        <Typography padding={1}>{safeFixed(analysis.row.gap)}</Typography>
        {Number.isFinite(analysis.row.gap) && analysis.row.gap <= -1.5 && (
          <LowerIcon />
        )}
        {Number.isFinite(analysis.row.gap) && analysis.row.gap >= 1.5 && (
          <HigherIcon />
        )}
      </>
    ),
  },
];

export const TeamSelfPeerOutlierColumns = [
  {
    headerName: "Team",
    field: "label",
    flex: 400,
  },
  {
    headerName: "Self",
    field: "self",
    flex: 66,
    minWidth: 75,
    valueFormatter: ({ value }: GridValueFormatterParams<number>) =>
      safeFixed(value),
    renderCell: (analysis: GridRenderCellParams<Analysis>) => (
      <>
        <Typography padding={1}>{safeFixed(analysis.row.self)}</Typography>
        {Number.isFinite(analysis.row.self) && analysis.row.self <= 4.0 && (
          <LowerIcon />
        )}
      </>
    ),
  },
  {
    headerName: "Peers",
    field: "others",
    flex: 66,
    minWidth: 75,
    valueFormatter: ({ value }: GridValueFormatterParams<number>) =>
      safeFixed(value),
    renderCell: (analysis: GridRenderCellParams<Analysis>) => (
      <>
        <Typography padding={1}>{safeFixed(analysis.row.others)}</Typography>
        {Number.isFinite(analysis.row.others) && analysis.row.others <= 4.0 && (
          <LowerIcon />
        )}
      </>
    ),
  },
  {
    headerName: "Gap",
    field: "gap",
    flex: 66,
    minWidth: 75,
    valueFormatter: ({ value }: GridValueFormatterParams<number>) =>
      safeFixed(value),
    renderCell: (analysis: GridRenderCellParams<Analysis>) => (
      <>
        <Typography padding={1}>{safeFixed(analysis.row.gap)}</Typography>
        {Number.isFinite(analysis.row.gap) && analysis.row.gap <= -1.5 && (
          <LowerIcon />
        )}
        {Number.isFinite(analysis.row.gap) && analysis.row.gap >= 1.5 && (
          <HigherIcon />
        )}
      </>
    ),
  },
];

export const GroupSelfPeerColumns = [
  {
    headerName: "Question",
    field: "question",
    flex: 400,
  },
  {
    headerName: "User",
    field: "user",
    flex: 150,
  },
  {
    headerName: "Self",
    field: "self",
    flex: 66,
    valueFormatter: ({ value }: GridValueFormatterParams<number>) =>
      safeFixed(value),
  },
  {
    headerName: "Peers",
    field: "peers",
    flex: 66,
    valueFormatter: ({ value }: GridValueFormatterParams<number>) =>
      safeFixed(value),
  },
  {
    headerName: "Gap",
    field: "gap",
    flex: 66,
    valueFormatter: ({ value }: GridValueFormatterParams<number>) =>
      safeFixed(value),
  },
];

export const GroupGivenColumns = [
  {
    headerName: "Question",
    field: "question",
    flex: 400,
  },
  {
    headerName: "User",
    field: "user",
    flex: 150,
  },
  {
    headerName: "Given",
    field: "given",
    flex: 66,
    valueFormatter: ({ value }: GridValueFormatterParams<number>) =>
      safeFixed(value),
  },
];

export const AverageSelfPeerColumns = [
  {
    headerName: "User",
    field: "user",
    flex: 400,
  },
  {
    headerName: "Self",
    field: "self",
    flex: 66,
    valueFormatter: ({ value }: GridValueFormatterParams<number>) =>
      safeFixed(value),
  },
  {
    headerName: "Peers",
    field: "others",
    flex: 66,
    valueFormatter: ({ value }: GridValueFormatterParams<number>) =>
      safeFixed(value),
  },
  {
    headerName: "Gap",
    field: "gap",
    flex: 66,
    valueFormatter: ({ value }: GridValueFormatterParams<number>) =>
      safeFixed(value),
    cellClassName: ({ value }: GridCellParams<number>) =>
      value && value > 0
        ? "alignment-positive"
        : value && value < 0
          ? "alignment-negative"
          : "",
  },
];

export const AverageGivenColumns = [
  {
    headerName: "User",
    field: "user",
    flex: 400,
  },
  {
    headerName: "Given",
    field: "given",
    flex: 66,
    valueFormatter: ({ value }: GridValueFormatterParams<number>) =>
      safeFixed(value),
  },
];

//===== Non-peer responses

export const IndividualColumns = [
  {
    headerName: "Question",
    field: "question",
    flex: 400,
  },
  {
    headerName: "Value",
    field: "peers",
    flex: 350,
    valueFormatter: ({ value }: GridValueFormatterParams<number>) =>
      safeFixed(value),
  },
];

export const IndividualOutlierColumns = [
  {
    headerName: "Name",
    field: "label",
    flex: 400,
  },
  {
    headerName: "Value",
    field: "others",
    flex: 350,
    minWidth: 75,
    valueFormatter: ({ value }: GridValueFormatterParams<number>) =>
      safeFixed(value),
    renderCell: (analysis: GridRenderCellParams<Analysis>) => (
      <>
        <Typography padding={1}>{safeFixed(analysis.row.others)}</Typography>
        {Number.isFinite(analysis.row.others) && analysis.row.others <= 4.0 && (
          <LowerIcon />
        )}
      </>
    ),
  },
];

export const TeamOutlierColumns = [
  {
    headerName: "Team",
    field: "label",
    flex: 400,
  },
  {
    headerName: "Value",
    field: "others",
    flex: 350,
    minWidth: 75,
    valueFormatter: ({ value }: GridValueFormatterParams<number>) =>
      safeFixed(value),
    renderCell: (analysis: GridRenderCellParams<Analysis>) => (
      <>
        <Typography padding={1}>{safeFixed(analysis.row.others)}</Typography>
        {Number.isFinite(analysis.row.others) && analysis.row.others <= 4.0 && (
          <LowerIcon />
        )}
      </>
    ),
  },
];

export const AverageColumns = [
  {
    headerName: "User",
    field: "user",
    flex: 400,
  },
  {
    headerName: "Value",
    field: "others",
    flex: 350,
    valueFormatter: ({ value }: GridValueFormatterParams<number>) =>
      safeFixed(value),
  },
];

export const AlignmentColumns = [
  {
    headerName: "Section",
    field: "section",
    flex: 400,
  },
  {
    headerName: "Alignment",
    field: "alignment",
    flex: 66,
    cellClassName: ({ value }: GridCellParams<number>) =>
      value && value >= 1.15
        ? "alignment-positive"
        : value && value <= 0.85
          ? "alignment-negative"
          : "",
    valueFormatter: ({ value }: GridValueFormatterParams<number>) =>
      safeFixed(value),
    renderCell: (
      row: GridRenderCellParams<{ section: string; alignment: number }>,
    ) => (
      <Typography padding={1}>
        {safeFixedPercent(row.row.alignment) + "%"}
      </Typography>
    ),
  },
];

export const GroupColumns = [
  {
    headerName: "Question",
    field: "question",
    flex: 400,
  },
  {
    headerName: "User",
    field: "user",
    flex: 150,
  },
  {
    headerName: "Value",
    field: "peers",
    flex: 200,
    valueFormatter: ({ value }: GridValueFormatterParams<number>) =>
      safeFixed(value),
  },
];

// === Free text responses

export const IndividualTextColumns = [
  {
    headerName: "Sender",
    field: "respondent",
    flex: 100,
  },
  {
    headerName: "Response",
    field: "comment",
    flex: 400,
  },
];

export const GroupTextColumns = [
  {
    headerName: "Recipient",
    field: "recipient",
    flex: 100,
  },
  {
    headerName: "Sender",
    field: "respondent",
    flex: 100,
  },
  {
    headerName: "Response",
    field: "comment",
    flex: 400,
  },
];

// === Point allocation responses

export const IndividualPointColumns = [
  {
    headerName: "Question",
    field: "question",
    flex: 400,
  },
  {
    headerName: "Sender",
    field: "sender",
    flex: 200,
  },
  {
    headerName: "Points",
    field: "value",
    flex: 200,
  },
];

export const GroupPointColumns = [
  {
    headerName: "Question",
    field: "question",
    flex: 400,
  },
  {
    headerName: "Recipient",
    field: "user",
    flex: 150,
  },
  {
    headerName: "Sender",
    field: "sender",
    flex: 150,
  },
  {
    headerName: "Points",
    field: "value",
    flex: 200,
  },
];
