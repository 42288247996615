import React from "react";
import { groupBy } from "../../Helpers";
import { FormItem, Template as TemplateType } from "../../types";

import {
  DragDropContext,
  Droppable,
  OnDragEndResponder,
} from "@hello-pangea/dnd";
import { TemplateCategory } from "./Category";

export interface Props {
  template: TemplateType;
  onAdd: (event: React.MouseEvent<HTMLButtonElement>, category: string) => void;
  onChange: (template: TemplateType) => void;
  editing: string[];
  setEditing: (x: string[]) => void;
}

export const TemplateForm = ({
  template,
  onAdd,
  onChange,
  editing,
  setEditing,
}: Props) => {
  const categories = groupBy(template.items, (question) => question.category);

  const onCategoryDragEnd = (categoryKey: string, index: number) => {
    const category = categories.find((x) => x.key === categoryKey);
    const sortCategories = categories.filter((x) => x.key !== categoryKey);
    if (!category) {
      return;
    }
    sortCategories.splice(index || 0, 0, category);

    let order = 0;
    const formItems: FormItem[] = [];

    sortCategories.forEach((category) => {
      category.members.forEach((question) => {
        formItems.push({
          ...question,
          order: order++,
        });
      });
    });

    onChange({
      ...template,
      items: formItems,
    });
  };

  const onQuestionDragEnd: OnDragEndResponder = (result) => {
    const { destination, source, draggableId, type } = result;

    if (type === "CATEGORY") {
      if (destination) {
        onCategoryDragEnd(draggableId, destination.index);
      }
      return;
    }

    const sortCategories = categories.map((x) => ({
      ...x,
      members: [...x.members],
    }));
    const sourceCategory = sortCategories.find(
      (x) => x.key === source.droppableId,
    );
    const destinationCategory = sortCategories.find(
      (x) => x.key === destination?.droppableId,
    );
    const draggable = template.items.find(
      (target) => target.id === draggableId,
    );

    if (!draggable || !sourceCategory) {
      return;
    }

    sourceCategory.members = sourceCategory.members.filter(
      (x) => x.id !== draggableId,
    );

    let newCategory = draggable.category;
    if (destinationCategory) {
      destinationCategory.members.splice(destination?.index || 0, 0, draggable);
      newCategory = destinationCategory.key;
    } else {
      newCategory = "New Category";
      sortCategories.push({ key: newCategory, members: [draggable] });
    }

    let order = 0;
    const questions: FormItem[] = [];

    sortCategories.forEach((category) => {
      category.members.forEach((question) => {
        questions.push({
          ...question,
          category:
            question.id === draggableId ? newCategory : question.category,
          order: order++,
        });
      });
    });

    onChange({
      ...template,
      items: questions,
    });
  };

  return (
    <DragDropContext onDragEnd={onQuestionDragEnd}>
      <Droppable droppableId="category-drop-zone" type="CATEGORY">
        {(provided) => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            {categories.map((category, index) => (
              <TemplateCategory
                key={category.key}
                category={category}
                index={index}
                template={template}
                onAdd={onAdd}
                onChange={onChange}
                editing={editing}
                setEditing={setEditing}
              />
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};
