import React from "react";
import { Box, Grid } from "@mui/material";
import { Layout } from "../Layouts/Layout";
import { ResponseCard } from "../Components/Response/Card";
import { GET_MY_RESPONSES, MyResponsesResult } from "../Queries";
import { useTranslation } from "react-i18next";
import { useQuery } from "@apollo/client";

export const OpenResponses = () => {
  const [t] = useTranslation();

  const { loading: responsesLoading, data: responsesData } =
    useQuery<MyResponsesResult>(GET_MY_RESPONSES, {
      variables: {
        filters: {},
      },
    });

  const responses = responsesData ? responsesData.responses : [];
  const sortedResponses = [...responses].sort((responseA, responseB) => {
    const staleStates = ["CL", "AR"];
    const openOrder =
      (responseA.submitted || staleStates.includes(responseA.campaignState)
        ? 1
        : 0) -
      (responseB.submitted || staleStates.includes(responseB.campaignState)
        ? 1
        : 0);
    const createdOrder =
      (responseA.campaignDueDate
        ? new Date(responseA.campaignDueDate).getTime()
        : 0) -
      (responseB.campaignDueDate
        ? new Date(responseB.campaignDueDate).getTime()
        : 0);
    return openOrder == 0 ? createdOrder : openOrder;
  });

  const showLoading = responsesLoading;

  return (
    <Layout
      breadcrumbs={[{ title: t("breadcrumbs.yourSurveys") }]}
      loading={showLoading}
      studentVariant
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          mb: 2,
        }}
      >
        <div></div>
      </Box>

      <Grid container spacing={2}>
        {sortedResponses.map((response) => (
          <Grid item key={response.id} xs={12} md={6} lg={4}>
            <ResponseCard response={response} />
          </Grid>
        ))}
      </Grid>
    </Layout>
  );
};
