import React from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Typography,
} from "@mui/material";
import { Add } from "@mui/icons-material";

export interface Props {
  onAddTeamClick: () => void;
}

export const TeamEmpty = ({ onAddTeamClick }: Props) => {
  return (
    <Card>
      <CardHeader title="It all starts with teamwork." />
      <CardContent>
        <Typography>
          Get ready to run your first feedback campaign by creating your teams.
          Simply add teams below and select students in the roster to add to
          each team.
        </Typography>
        <Box sx={{ textAlign: "center", mt: 2 }}>
          <Button
            variant="contained"
            aria-label="add team"
            endIcon={<Add />}
            onClick={onAddTeamClick}
            size="large"
          >
            Add Team
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
};
