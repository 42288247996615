import React from "react";
import {
  Button,
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
} from "@mui/material";
import { MyResponse } from "../../types";
import { ResponseState } from "./State";
import { ResponseProgress } from "./Progress";

export interface Props {
  response: MyResponse;
}

export const ResponseCard = ({ response }: Props) => {
  const staleStates = ["CL", "AR"];
  const submittable = !(
    response.submitted || staleStates.includes(response.campaignState)
  );
  const viewable = Boolean(response.campaignReleased);

  const disabled = !submittable && !viewable;
  const link = submittable
    ? response.tokenizedLink
    : response.campaignResultsLink;

  return (
    <Card sx={{ opacity: disabled ? 0.5 : 1.0 }}>
      <CardActionArea href={link} disabled={disabled}>
        <CardHeader
          action={
            <>
              <ResponseState response={response} />
            </>
          }
          title={response.campaignName}
          titleTypographyProps={{
            style: { color: "inherit", textDecoration: "none" },
          }}
          subheader={
            response.campaignDueDate
              ? `Due ${new Date(response.campaignDueDate).toLocaleDateString()}`
              : `No due date`
          }
        />
        <CardContent sx={{ display: "flex", justifyContent: "right" }}>
          {disabled && <ResponseProgress response={response} />}
          {submittable && (
            <Button href={response.tokenizedLink} variant="contained">
              Respond Now
            </Button>
          )}
          {viewable && !submittable && (
            <Button
              href={response.campaignResultsLink}
              variant="contained"
              color="secondary"
            >
              View Results
            </Button>
          )}
        </CardContent>
      </CardActionArea>
    </Card>
  );
};
