import * as React from "react";
import { Button, ButtonProps, Link } from "@mui/material";

export interface Props {
  contents: string;
  filename: string;
  label: string;
  mimeType: string;
  buttonProps?: Pick<ButtonProps, "variant" | "sx">;
}

export function DownloadLink({
  buttonProps,
  contents,
  filename,
  label,
  mimeType,
}: Props) {
  const blob = new Blob([contents], { type: mimeType });
  const url = URL.createObjectURL(blob);
  return (
    <Button {...buttonProps} component={Link} download={filename} href={url}>
      {label}
    </Button>
  );
}
