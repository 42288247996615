import React from "react";
import { Box } from "@mui/material";
import { MyResponse } from "../../types";
import { CheckCircle, Lock } from "@mui/icons-material";

export interface Props {
  response: MyResponse;
}

export const ResponseProgress = ({ response }: Props) => {
  return (
    <>
      {response.submitted ? (
        <CheckCircle
          sx={{
            mr: 1,
            color: "green",
          }}
          fontSize="large"
        />
      ) : (
        <Lock
          sx={{
            mr: 1,
            color: "red",
          }}
          fontSize="large"
        />
      )}
    </>
  );
};
