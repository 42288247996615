import React from "react";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { GroupCategory } from "../../types";
import { useTranslation } from "react-i18next";

export interface Props {
  categories: GroupCategory[];
  selected?: GroupCategory;
  onChange: (groupcategory: GroupCategory | null) => void;
}

export const GroupCategorySelector = ({
  categories,
  selected,
  onChange,
}: Props) => {
  const [t] = useTranslation();
  return (
    <FormControl size="small" fullWidth>
      <InputLabel id="category-select-label">
        {t("categories.category")}
      </InputLabel>
      <Select
        labelId="category-select-label"
        id="category-select"
        value={selected ? selected.id : "all"}
        label={t("categories.category")}
        onChange={(e) => {
          const target =
            e.target.value === "all"
              ? null
              : categories.find((target) => target.id == e.target.value);
          target !== undefined && onChange(target);
        }}
      >
        <MenuItem value={"all"}>All Group Categories</MenuItem>
        {categories.map((category, index) => (
          <MenuItem key={index} value={category.id}>
            {category.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
