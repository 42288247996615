import React, { useState } from "react";
import {
  Autocomplete,
  ListItem,
  ListItemAvatar,
  ListItemText,
  SxProps,
  TextField,
} from "@mui/material";
import { User } from "../../types";
import { UserAvatar } from "./UserAvatar";
import { getUserName } from "../../DataTransform";

export interface Props {
  showAvatars?: boolean;
  label: string;
  users: User[];
  onChange: (user: User) => void;
  sx?: SxProps;
}

export const UserAutocomplete = ({
  showAvatars,
  label,
  users,
  onChange,
  sx,
}: Props) => {
  const [value, setValue] = useState<undefined | null>(null);

  function toggleValue() {
    setValue(value === undefined ? null : undefined);
  }

  return (
    <Autocomplete
      id="user-autocomplete"
      value={value}
      sx={sx}
      autoHighlight
      options={users}
      clearIcon={<></>}
      getOptionLabel={(user) => getUserName(user)}
      renderOption={(props, option) => (
        <ListItem {...props}>
          {showAvatars && (
            <ListItemAvatar>
              <UserAvatar user={option} />
            </ListItemAvatar>
          )}
          <ListItemText primary={getUserName(option)} />
        </ListItem>
      )}
      renderInput={(params) => (
        <TextField {...params} label={label} variant="standard" />
      )}
      onChange={(event, target) => {
        target && onChange(target);
        toggleValue();
      }}
    />
  );
};
