import React from "react";

import { LicenseClaim } from "./Claim";
import { useMutation, useQuery } from "@apollo/client";
import { CLAIM_LICENSE, GET_LICENSE, LicenseResult } from "../../Queries";

interface ComponentProps {
  jwt?: string;
}

interface ComponentProps {
  jwt?: string;
  children?: React.ReactNode;
}

const LicenseGate: React.FC<ComponentProps> = ({ jwt, children }) => {
  const { loading: roleLoading, data: roleData } = useQuery<LicenseResult>(
    GET_LICENSE,
    {
      context: {
        headers: {
          Authorization: `JWT ${jwt}`,
        },
      },
    },
  );

  const [claimLicense, { loading: claimPending, error: claimError }] =
    useMutation(CLAIM_LICENSE, {
      refetchQueries: ["MyLicense"],
    });

  if (roleLoading) {
    return <div>Loading...</div>;
  }

  const isLicensed = !roleData?.myLicense.includes("unlicensed");

  const errorMessage = claimError && claimError.message;

  async function handleClaim(tokenString: string) {
    await claimLicense({
      variables: {
        tokenString,
      },
      context: {
        headers: {
          Authorization: `JWT ${jwt}`,
        },
      },
    });
  }

  return isLicensed ? (
    <>{children}</>
  ) : (
    <LicenseClaim
      onClaim={handleClaim}
      isPending={claimPending}
      errorMessage={errorMessage}
    />
  );
};

export default LicenseGate;
