import React from "react";
import { Typography } from "@mui/material";
import CircularProgress, {
  CircularProgressProps,
} from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

export interface Props {
  label: string;
  scale?: number;
  value: number;
}

function decimalToHex(d: number, padding: number) {
  d = Math.floor(between(d, 0, 255));
  let hex = Number(d).toString(16);
  padding =
    typeof padding === "undefined" || padding === null
      ? (padding = 2)
      : padding;

  while (hex.length < padding) {
    hex = "0" + hex;
  }

  return hex;
}

function between(num: number, min: number, max: number) {
  return Math.max(min, Math.min(num, max));
}
export function getColor(proportion: number) {
  let red = 0;
  let green = 0;
  const blue = 0;
  if (proportion < 0.5) {
    red = 255;
    green = proportion * 2 * 256;
  } else {
    red = (1 - proportion) * 2 * 256;
    green = 255;
  }
  return `#${decimalToHex(red, 2)}${decimalToHex(green, 2)}${decimalToHex(
    blue,
    2,
  )}`;
}

function CircularProgressWithLabel(
  props: CircularProgressProps & { value: number; displayValue?: number },
) {
  const { displayValue, value } = props;
  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <CircularProgress variant="determinate" {...props} />
      <Box
        sx={{
          top: 10,
          left: 10,
          bottom: 10,
          right: 10,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "#000",
          borderRadius: 25,
        }}
      >
        <Typography variant="h4" component="div" color="text.primary">{`${
          displayValue ? displayValue.toFixed(1) : value.toFixed(1)
        }`}</Typography>
      </Box>
    </Box>
  );
}

export const AnalysisScoreChart = ({ label, scale = 5, value }: Props) => {
  const proportion = value / scale;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        p: 3,
      }}
    >
      <CircularProgressWithLabel
        variant="determinate"
        value={100 * proportion}
        displayValue={value}
        sx={{ display: "block", color: getColor(proportion) }}
        size={100}
      />

      <Typography sx={{ lineHeight: "40px" }} color="text.secondary">
        {label}
      </Typography>
    </Box>
  );
};
