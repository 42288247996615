import { ItemSubjectType, ResponseSubjectType, User } from "../../types";

export default function (
  responseSubjectType: ResponseSubjectType,
  itemSubjectType: ItemSubjectType,
  author?: User,
  subject?: User,
): boolean {
  const directMatch =
    (responseSubjectType as string) === (itemSubjectType as string);
  return directMatch
    ? true
    : responseSubjectType === ResponseSubjectType.Peer &&
        itemSubjectType === ItemSubjectType.PeerOnly &&
        (author === undefined ||
          subject === undefined ||
          author.id !== subject.id);
}
