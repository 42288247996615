import React, { useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Layout } from "../Layouts/Layout";
import { Campaign } from "../types";
import { CampaignCard } from "../Components/Campaign/Card";
import { Add } from "@mui/icons-material";
import { useNavigate } from "react-router";
import {
  GET_SECTIONS,
  SectionsResult,
  GET_FORMS,
  FormsResult,
  GET_CAMPAIGNS,
  CampaignsResult,
  ADD_CAMPAIGN,
  UPDATE_CAMPAIGN,
  DELETE_CAMPAIGN,
  SEND_CAMPAIGN_REMINDERS,
} from "../Queries";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "@apollo/client";
import { sortByActivity } from "../Data";

export const Campaigns = () => {
  const {
    loading: sectionsLoading,
    error: sectionsError,
    data: sectionsData,
  } = useQuery<SectionsResult>(GET_SECTIONS, { variables: { filters: {} } });
  const sections = sectionsData ? sectionsData.sections : undefined;
  const navigate = useNavigate();
  const [sectionId, setSectionId] = useState(undefined as string | undefined);
  const { loading: formsLoading, data: formsData } = useQuery<FormsResult>(
    GET_FORMS,
    { variables: { filters: {} } },
  );
  const [addBusy, setAddBusy] = useState(false);
  const [t] = useTranslation();

  const { loading: campaignsLoading, data: campaignsData } =
    useQuery<CampaignsResult>(GET_CAMPAIGNS, {
      variables: {
        filters: {
          sectionId: sectionId ? sectionId : undefined,
        },
      },
    });

  const campaigns = campaignsData ? campaignsData.campaigns : [];

  const showLoading = sectionsLoading || sectionsLoading || campaignsLoading;

  const [addCampaign] = useMutation(ADD_CAMPAIGN, {
    refetchQueries: ["GetCampaigns"],
  });
  const [updateCampaign] = useMutation(UPDATE_CAMPAIGN, {
    refetchQueries: ["GetCampaigns"],
  });
  const [deleteCampaign] = useMutation(DELETE_CAMPAIGN, {
    refetchQueries: ["GetCampaigns"],
  });
  const [sendCampaignReminders] = useMutation(SEND_CAMPAIGN_REMINDERS, {
    refetchQueries: ["GetCampaign"],
  });

  if (!sectionsLoading && !sections) {
    throw new Error("No sections found");
  }

  async function handleAddCampaign() {
    const now = new Date();
    const dueDate = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate(),
      23,
      59,
      0,
      0,
    );

    setAddBusy(true);
    const { data: newCampaignResult } = await addCampaign({
      variables: {
        name: "New Survey",
        sectionId: sectionId,
        dueDate,
      },
    });
    setAddBusy(false);

    if (!newCampaignResult) {
      return;
    }
    const { addCampaign: newCampaign } = newCampaignResult;
    navigate(`/campaigns/${newCampaign.id}`);
  }

  async function handleCampaignChange(
    changedCampaign: Campaign,
    activityLabel: string,
  ) {
    await updateCampaign({
      variables: {
        ...changedCampaign,
        activityLabel,
      },
    });
  }

  async function handleDelete(campaign: Campaign) {
    const confirmed = window.confirm(
      t("campaigns.confirmDelete", { name: campaign.name }) as string,
    );
    if (!confirmed) {
      return;
    }

    await deleteCampaign({
      variables: {
        id: campaign.id,
      },
    });
  }

  async function handleRemind(campaign: Campaign) {
    const sent = await sendCampaignReminders({
      variables: {
        id: campaign.id,
      },
      context: {
        debounceKey: `campaign-${campaign.id}`,
      },
    });

    if (sent) {
      alert("Reminders sent successfully");
    } else {
      alert("No reminders to send");
    }
  }

  return (
    <Layout
      breadcrumbs={[{ title: t("breadcrumbs.campaigns") }]}
      loading={showLoading}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          mb: 2,
        }}
      >
        {/* Commented out SectionSelector for now */}
        {/* {!sectionsLoading && sections ? (
          <SectionSelector
            sections={sections}
            selected={section}
            onChange={(section) => setSectionId(section ? section.id : "")}
          />
        ) : (
          <>Sections loading...</>
        )} */}
        <div></div>
        <Box>
          <LoadingButton
            variant="contained"
            aria-label="add campaign"
            endIcon={<Add />}
            onClick={handleAddCampaign}
            loading={addBusy}
          >
            {t("campaigns.add")}
          </LoadingButton>
        </Box>
      </Box>

      <Grid container spacing={2}>
        {sortByActivity(campaigns, t("campaigns.events.send")).map(
          (campaign) => (
            <Grid item key={campaign.id} xs={12} md={6} lg={4}>
              <CampaignCard
                campaign={campaign}
                onChange={handleCampaignChange}
                onDelete={handleDelete}
                onRemind={handleRemind}
              />
            </Grid>
          ),
        )}
      </Grid>
    </Layout>
  );
};
