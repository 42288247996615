import React from "react";
import { Avatar, SxProps } from "@mui/material";
import { Star as StarIcon } from "@mui/icons-material";
import { User } from "../../types";
import { getUserName } from "../../DataTransform";

function stringToColor(string: string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function stringAvatar(name: string) {
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
  };
}

export function UserAvatar({
  sx,
  user,
  starred = false,
}: {
  sx?: SxProps;
  user: User;
  starred?: boolean;
}) {
  if (user.headshot) {
    return (
      <>
        <Avatar
          alt={`Avatar for ${getUserName(user)}`}
          src={user.headshot}
          sx={sx}
        />
        {starred && (
          <StarIcon
            color={"primary"}
            fontSize="small"
            sx={{
              position: "absolute",
              top: 0,
              left: 4,
              stroke: "white",
              strokeWidth: 1,
            }}
          />
        )}
      </>
    );
  } else {
    return (
      <>
        <Avatar
          alt={`Avatar for ${getUserName(user)}`}
          {...stringAvatar(getUserName(user))}
        />
        {starred && (
          <StarIcon
            color={"primary"}
            fontSize="small"
            sx={{
              position: "absolute",
              top: 0,
              left: 4,
              stroke: "white",
              strokeWidth: 1,
            }}
          />
        )}
      </>
    );
  }
}
