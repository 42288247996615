import React from "react";
import { ThinLayout } from "../Layouts/ThinLayout";

export const Loading = () => {
  return (
    <ThinLayout title={"Loading Feedback Loop..."} loading={true}>
      Give us a moment.
    </ThinLayout>
  );
};
