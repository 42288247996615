import React, { useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { Course } from "../../types";

export interface Props {
  course: Course;
  onClose: () => void;
  onConnect: () => void;
  onEnable: (enabled: boolean) => void;
}

export const ImportModal = ({
  course,
  onClose,
  onConnect,
  onEnable,
}: Props) => {
  function handleOAuthMessage(event: MessageEvent<string>) {
    if (event.data === "OBTAIN_LMS_TOKEN_SUCCESSFUL") {
      onConnect();
    }
  }

  useEffect(() => {
    window.addEventListener("message", handleOAuthMessage);

    return () => {
      window.removeEventListener("message", handleOAuthMessage);
    };
  }, []);

  function onOpen() {
    window.open(
      course.oauthUrl,
      "Canvas Connect",
      `dependent=${1}, alwaysOnTop=${1}, alwaysRaised=${1}, alwaysRaised=${1}, width=${800}, height=${450}`,
    );
  }

  return (
    <Dialog open={true} onClose={onClose}>
      <DialogTitle id="import-dialog-title">
        {course.fullSyncReady
          ? "Enable automatic team sync?"
          : "Connect your Canvas account"}
      </DialogTitle>
      <DialogContent>
        {course.fullSyncReady ? (
          <DialogContentText id="import-dialog-description">
            Would you like to enable automatic course section and group sync
            with your LMS?
          </DialogContentText>
        ) : (
          // <iframe src={course.oauthUrl} />
          <DialogContentText id="import-dialog-description">
            We need to pair with your Canvas account to enable automatic course
            section and group sync.
          </DialogContentText>
        )}
      </DialogContent>
      <DialogActions>
        {course.fullSyncReady ? (
          <>
            <Button onClick={onClose}>Cancel</Button>
            <Button onClick={() => onEnable(true)} autoFocus>
              Proceed
            </Button>
          </>
        ) : (
          <Button onClick={() => onOpen()}>Login with Canvas</Button>
        )}
      </DialogActions>
    </Dialog>
  );
};
