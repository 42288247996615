import React from "react";
import { Card, CardHeader, CardContent, Typography } from "@mui/material";
import { groupBy } from "../../Helpers";
import {
  ResponseSubject as SubjectResponseType,
  FormItem,
  User,
  Answer,
  ItemType,
  ResponseSubjectType,
} from "../../types";
import { QuestionView } from "../Question/View";
import { getUserName } from "../../DataTransform";

export interface Props {
  subjectResponse: SubjectResponseType;
  author: User;
  subject: User;
  formItems: FormItem[];
  onChange: (subjectResponse: SubjectResponseType) => void;
}

export const getSubjectResponseWithChangedValue = (
  subjectResponse: SubjectResponseType,
  formItem: FormItem,
  value: number | string,
) => {
  const clone = { ...subjectResponse };
  clone.answers = [...clone.answers];
  // clone.answers[formItem.id] = value;
  const answer = subjectResponse.answers.find(
    (answer) => answer.itemId === formItem.id,
  );
  const clonedAnswer =
    answer !== undefined
      ? ({ ...answer } as Answer)
      : ({
          id: "",
          itemId: formItem.id,
          modified: "",
          value: undefined,
          text: undefined,
        } as Answer);
  if (clonedAnswer !== undefined) {
    if (typeof value === "number") {
      clonedAnswer.value = value;
      clonedAnswer.text = "";
    } else {
      clonedAnswer.text = value;
      clonedAnswer.value = null;
    }
  }
  clone.answers =
    answer !== undefined
      ? clone.answers.map((target) =>
          target.id === clonedAnswer.id ? clonedAnswer : target,
        )
      : [...clone.answers, clonedAnswer];
  return clone;
};

export const ResponseSubject = ({
  subjectResponse,
  formItems,
  subject,
  author,
  onChange,
}: Props) => {
  const categories = groupBy(formItems, (formItem) => formItem.category);

  function handleValueChange(formItem: FormItem, value: number | string) {
    const clone = getSubjectResponseWithChangedValue(
      subjectResponse,
      formItem,
      value,
    );
    // console.log(clone);
    onChange(clone);
  }

  function getHeading(subjectResponse: SubjectResponseType, subject: User) {
    if (subjectResponse.subjectType === ResponseSubjectType.General) {
      return "";
    }

    return subject === author
      ? `Please evaluate yourself (${getUserName(subject)})`
      : `Please evaluate ${getUserName(subject)}`;
  }

  return (
    <>
      {getHeading(subjectResponse, subject) && (
        <Typography variant="h5">
          {getHeading(subjectResponse, subject)}
        </Typography>
      )}

      {categories.map((category) => (
        <Card key={category.key} sx={{ mt: 2 }}>
          <CardHeader title={category.key} />
          <CardContent>
            {category.members.map((formItem, index) => {
              const answer = subjectResponse.answers.find(
                (answer) => answer.itemId === formItem.id,
              );
              return (
                <QuestionView
                  value={
                    formItem.itemType === ItemType.Text
                      ? answer?.text
                      : answer?.value
                  }
                  key={index}
                  formItem={formItem}
                  onValueChange={(value) => handleValueChange(formItem, value)}
                />
              );
            })}
          </CardContent>
        </Card>
      ))}
    </>
  );
};
