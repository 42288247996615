import React from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SxProps,
} from "@mui/material";
import { Team } from "../../types";

export interface Props {
  label: string;
  teams: Team[];
  selected?: Team;
  onChange: (team: Team) => void;
  sx?: SxProps;
}

export const TeamSelector = ({
  label,
  teams,
  selected,
  onChange,
  sx,
}: Props) => {
  return (
    <FormControl style={{ minWidth: 190, maxWidth: 190 }} size="small" sx={sx}>
      <InputLabel id="team-select-label">{label}</InputLabel>
      <Select
        labelId="team-select-label"
        id="team-select"
        value={selected?.id || []}
        label={label}
        onChange={(e) => {
          const target = teams.find((target) => target.id == e.target.value);
          target && onChange(target);
        }}
      >
        {teams.map((team, index) => (
          <MenuItem key={index} value={team.id}>
            {team.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
