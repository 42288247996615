import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { OBTAIN_LMS_TOKEN } from "../Queries";
import { ThinLayout } from "../Layouts/ThinLayout";
import { Alert } from "@mui/material";

export const OAuth = () => {
  const [searchParams] = useSearchParams();

  const state = searchParams.get("state");
  const code = searchParams.get("code");

  const [obtainLmsToken, { loading, error }] = useMutation(OBTAIN_LMS_TOKEN);

  async function tryObtainingToken() {
    const result = await obtainLmsToken({
      variables: {
        code,
        state,
      },
      context: {
        debounceKey: `obtainLmsToken`,
      },
    });

    if (result) {
      window.opener.postMessage("OBTAIN_LMS_TOKEN_SUCCESSFUL", "*");
      window.close();
    }
  }

  useEffect(() => {
    tryObtainingToken();
  }, []);

  return (
    <ThinLayout title={"Please wait"} loading={loading}>
      <>
        {!loading ? (
          error ? (
            <Alert severity="error">
              We were unable to complete your connection to Canvas. Please try
              again later.
            </Alert>
          ) : (
            <Alert severity="success">
              Success! This should redirect momentarily.
            </Alert>
          )
        ) : (
          <></>
        )}
      </>
    </ThinLayout>
  );
};
