import React from "react";
import { Chip, SxProps } from "@mui/material";
import { MyResponse } from "../../types";

export interface Props {
  response: MyResponse;
  sx?: SxProps;
}

export const ResponseState = ({ response, sx }: Props) => {
  return (
    <>
      {!response.submitted &&
        new Date(response.campaignDueDate) > new Date() && (
          <Chip
            label="Open"
            sx={{ ...sx, backgroundColor: "green", color: "black" }}
            variant="outlined"
          />
        )}
      {!response.submitted &&
        new Date(response.campaignDueDate) <= new Date() && (
          <Chip
            label="Past Due"
            sx={{ ...sx, backgroundColor: "yellow", color: "black" }}
            variant="outlined"
          />
        )}
    </>
  );
};
