import React, { useEffect, useState } from "react";
import { Box, Button, Tab, Typography, useTheme } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import {
  Response as ResponseType,
  FormItem,
  ResponseSubject as SubjectResponseType,
  User,
  ResponseSubjectType,
  ItemType,
} from "../../types";
import { ResponseSubject } from "./Subject";
import { BlingCheckmark } from "../BlingCheckmark";
import { authorFirst, getUserName } from "../../DataTransform";
import { SkipNext } from "@mui/icons-material";
import { ResponsePoints } from "./Points";
import isCompatible from "../SubjectType/compatibility";

export interface Props {
  response: ResponseType;
  author: User;
  subjects: User[];
  formItems: FormItem[];
  onChange: (value: ResponseType) => void;
  instructions: string;
  extraTopMargin?: boolean;
}

export const ResponseEdit = ({
  response,
  formItems,
  subjects,
  author,
  instructions,
  onChange,
  extraTopMargin = false,
}: Props) => {
  const sortedReponseSubjects = authorFirst(
    response.responseSubjects,
    author.id,
  );
  const [tab, setTab] = useState(sortedReponseSubjects[0].id);
  const [loading, setLoading] = useState(0);
  const theme = useTheme();

  const sortedItems = [...formItems].sort((a, b) => a.order - b.order);
  const pointsItems = sortedItems.filter(
    (item) => item.itemType === ItemType.Points,
  );

  const isLastTab =
    pointsItems.length > 0
      ? "points" === tab
      : sortedReponseSubjects[sortedReponseSubjects.length - 1].id === tab;
  const currentSubject = sortedReponseSubjects.find((sr) => sr.id === tab);

  useEffect(() => {
    if (loading < 200) {
      setTimeout(() => setLoading(loading + 20), 100);
    }
  }, [loading, setLoading]);

  function handleSubjectResponseChange(subjectResponse: SubjectResponseType) {
    const clone = { ...response };
    if (subjectResponse.completed) {
      setLoading(0);
    }
    clone.responseSubjects = clone.responseSubjects.map((target) =>
      target.id === subjectResponse.id ? subjectResponse : target,
    );
    onChange(clone);
  }

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTab(newValue);
  };

  function handleNext() {
    const index = sortedReponseSubjects.findIndex((sr) => sr.id === tab);
    if (index + 1 >= sortedReponseSubjects.length && pointsItems.length > 0) {
      setTab("points");
    } else {
      setTab(sortedReponseSubjects[index + 1].id);
    }

    // Scroll to page top after tab change
    setTimeout(() => window.scroll({ top: 0, left: 0, behavior: "smooth" }), 0);
  }

  const responsePairs = sortedReponseSubjects.map((subjectResponse) => {
    const subject = subjects.find(
      (target) => target.id === subjectResponse.subjectUserId,
    );
    return {
      subject: subject!,
      subjectResponse,
    };
  });

  function getName(pair: {
    subject: User;
    subjectResponse: SubjectResponseType;
  }) {
    const { subject: user, subjectResponse } = pair;

    if (subjectResponse.subjectType === ResponseSubjectType.General) {
      return "General Reflection";
    }

    return getUserName(user) + (user.id === author.id ? " (Yourself)" : "");
  }

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <TabContext value={tab}>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
              position: "fixed",
              top: extraTopMargin ? "85px" : "45px",
              left: "0px",
              backgroundColor: theme.palette.background.default,
              width: "100%",
              zIndex: 2,
            }}
          >
            <TabList
              value={tab}
              onChange={handleTabChange}
              aria-label="basic tabs example"
            >
              {responsePairs.map((pair, index) => (
                <Tab
                  disabled={
                    !pair.subjectResponse.completed &&
                    index >
                      responsePairs.reduce(
                        (acc, response) =>
                          acc + (response.subjectResponse.completed ? 1 : 0),
                        0,
                      )
                  }
                  key={index}
                  label={
                    pair.subjectResponse.completed ||
                    index <=
                      responsePairs.reduce(
                        (acc, response) =>
                          acc + (response.subjectResponse.completed ? 1 : 0),
                        0,
                      )
                      ? getName(pair)
                      : ""
                  }
                  value={pair.subjectResponse.id}
                  icon={
                    <BlingCheckmark
                      active={
                        tab === pair.subjectResponse.id &&
                        !pair.subjectResponse.completed
                      }
                      user={pair.subject}
                      completed={pair.subjectResponse.completed}
                      divProps={{ style: { marginRight: 10 } }}
                      size="small"
                    />
                  }
                  iconPosition="start"
                />
              ))}
              {pointsItems.length > 0 && (
                <Tab
                  disabled={
                    responsePairs.length >
                    responsePairs.reduce(
                      (acc, response) =>
                        acc + (response.subjectResponse.completed ? 1 : 0),
                      0,
                    )
                  }
                  label={
                    responsePairs.length <=
                    responsePairs.reduce(
                      (acc, response) =>
                        acc + (response.subjectResponse.completed ? 1 : 0),
                      0,
                    )
                      ? "Points"
                      : ""
                  }
                  value="points"
                  icon={
                    <BlingCheckmark
                      active={tab === "points"}
                      completed={false}
                      divProps={{ style: { marginRight: 10 } }}
                      size="small"
                    />
                  }
                  iconPosition="start"
                />
              )}
            </TabList>
          </Box>
          <Box sx={{ mt: 8 }}>&nbsp;</Box>
          {responsePairs.map((pair, index) => (
            <TabPanel key={index} value={pair.subjectResponse.id}>
              {pair.subjectResponse.subjectType !==
                ResponseSubjectType.General && (
                <>
                  <Typography variant="h5" sx={{ mb: 2 }}>
                    Instructions:
                  </Typography>
                  <Typography sx={{ mb: 2 }}>
                    {instructions.split("\n").map((x, index, arr) => (
                      <>
                        {x}
                        {index !== arr.length - 1 && <br />}
                      </>
                    ))}
                  </Typography>
                </>
              )}
              <ResponseSubject
                subjectResponse={pair.subjectResponse}
                author={author}
                subject={pair.subject}
                onChange={handleSubjectResponseChange}
                formItems={sortedItems.filter(
                  (item) =>
                    item.itemType !== ItemType.Points &&
                    isCompatible(
                      pair.subjectResponse.subjectType,
                      item.subjectType,
                      author,
                      pair.subject,
                    ),
                  // item.subjectType ===
                  //   (pair.subjectResponse
                  //     .subjectType as unknown as ItemSubjectType)
                )}
              />
            </TabPanel>
          ))}
          {pointsItems.length > 0 && (
            <TabPanel value="points">
              {pointsItems.map((pointsItem) => (
                <ResponsePoints
                  onChange={handleSubjectResponseChange}
                  formItem={pointsItem}
                  key={pointsItem.id}
                  subjects={subjects}
                  responseSubjects={sortedReponseSubjects}
                />
              ))}
            </TabPanel>
          )}
        </TabContext>
      </Box>
      <Button
        disabled={!currentSubject?.completed || isLastTab}
        variant="contained"
        endIcon={<SkipNext />}
        onClick={handleNext}
        sx={{ ml: 3 }}
      >
        Next
      </Button>
    </>
  );
};
