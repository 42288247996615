import React from "react";
import {
  Link as LinkIcon,
  BarChart,
  Delete as DeleteIcon,
  Preview,
} from "@mui/icons-material";
import { Card, CardContent, CardHeader, IconButton } from "@mui/material";
import {
  Campaign,
  CampaignState,
  Response,
  Team,
  Team as TeamType,
  User,
} from "../../types";
import { Link } from "react-router-dom";
import { UserList } from "../User/List";

export interface Props {
  campaign: Campaign;
  team: TeamType;
  responses: Response[];
  users: User[];
  onExclude: (campaign: Campaign, team: Team) => void;
}

export const CampaignTeam = ({
  campaign,
  team,
  responses,
  users,
  onExclude,
}: Props) => {
  function getUser(response: Response) {
    return users.find((user) => user.id === response.authorId);
  }

  function getResponse(user: User) {
    return responses.find((response) => user.id === response.authorId);
  }

  function handleExcludeClick(team: Team) {
    onExclude(campaign, team);
  }

  const responseAuthors = responses
    .map(getUser)
    .filter((target) => target !== undefined) as User[];

  // const responseSubjectUsers = responses
  //   .flatMap((response) => response.responseSubjects.filter((rs) => rs.subjectUserId !== null).map((rs) => rs.subjectUserId))
  //   .map((id) => users.find((user) => user.id === id))
  //   .filter((target) => target !== undefined) as User[];
  const responseSubjectUsers = team.userIds
    .map((id) => users.find((user) => user.id === id))
    .filter((target) => target !== undefined) as User[];

  const nonAuthorUsers = responseSubjectUsers.filter(
    (user) => !responseAuthors.includes(user),
  );

  const allUsers = [...responseAuthors, ...nonAuthorUsers];

  return (
    <Card>
      <CardHeader
        title={team.name}
        action={
          campaign.state == CampaignState.Draft ? (
            <IconButton
              edge="end"
              aria-label="exclude from campaign"
              // component={Link}
              // to={`/results/${campaign.id}/PR/${team.id}/`}
              sx={{ mr: "12px" }}
              onClick={() => handleExcludeClick(team)}
            >
              <DeleteIcon />
            </IconButton>
          ) : (
            <IconButton
              edge="end"
              aria-label="results"
              component={Link}
              to={`/results/${campaign.id}/PR/${team.id}/`}
              sx={{ mr: "12px" }}
            >
              <BarChart />
            </IconButton>
          )
        }
      />
      <CardContent>
        <UserList
          checked={responses
            .filter((response) => response.submitted)
            .map((response) => response.authorId)}
          users={allUsers}
          UserActions={({ user }) =>
            campaign.state === CampaignState.Draft ? (
              <></>
            ) : (
              <>
                <IconButton
                  edge="end"
                  aria-label="response link"
                  // component={Link}
                  href={getResponse(user)?.tokenizedLink || ""}
                  disabled={!responseAuthors.includes(user)}
                >
                  <LinkIcon />
                </IconButton>
                <IconButton
                  edge="end"
                  aria-label="result preview"
                  component={Link}
                  to={`/results/preview/${campaign.id}/${user.id}/campaign`}
                >
                  <Preview />
                </IconButton>
                <IconButton
                  edge="end"
                  aria-label="results"
                  component={Link}
                  to={`/results/${campaign.id}/PR/${team.id}/${user.id}`}
                >
                  <BarChart />
                </IconButton>
              </>
            )
          }
        />
      </CardContent>
    </Card>
  );
};
