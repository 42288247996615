import React from "react";
import { Box, CircularProgress } from "@mui/material";

export const ModalProgress = () => {
  return (
    <Box
      sx={{
        position: "absolute",
        top: "50%",
        left: "50%",
        ml: "-50px",
        mt: "-50px",
        width: "100px",
        height: "100px",
        background: "rgba(0,0,0,0.8)",
        zIndex: 1000,
        borderRadius: 5,
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          ml: "-20px",
          mt: "-20px",
        }}
      >
        <CircularProgress />
      </Box>
    </Box>
  );
};
