export const FeedbackLoopColors = [
  "#471aa0",
  "#8a1aa0",
  "#1a30a0",
  "#a01a30",
  "#1aa08a",
];

// export const FeedbackLoopColors = "qualitative";

export default FeedbackLoopColors;
