import React from "react";
import { Layout } from "../Layouts/Layout";
import { Campaign as CampaignType, GroupCategory, Team } from "../types";
import { CampaignEdit } from "../Components/Campaign/Edit";
import { useParams, useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import { useQuery, useMutation } from "@apollo/client";
import {
  GET_CAMPAIGN,
  FormsResult,
  GET_FORMS,
  SectionsResult,
  GET_SECTIONS,
  UPDATE_CAMPAIGN,
  UPDATE_ASSIGNMENT_POINTS,
  CampaignResult,
  UsersResult,
  GET_USERS,
  ResponsesResult,
  SEND_CAMPAIGN_REMINDERS,
  EXCLUDE_GROUP,
  GET_RESPONSES_SUMMARY,
  GroupCategoriesResult,
  GET_GROUP_CATEGORIES,
  SET_CAMPAIGN_GROUPCATEGORY,
  DELETE_CAMPAIGN,
} from "../Queries";
import { group } from "console";

export const EditCampaign = () => {
  const { campaignId } = useParams();
  const { loading: campaignLoading, data: campaignData } =
    useQuery<CampaignResult>(GET_CAMPAIGN, { variables: { pk: campaignId } });
  const navigate = useNavigate();

  const campaign = campaignData ? campaignData.campaign : undefined;

  const [updateCampaign] = useMutation(UPDATE_CAMPAIGN, {
    refetchQueries: ["GetResponsesSummary"],
  });

  const [updateAssignmentPoints] = useMutation(UPDATE_ASSIGNMENT_POINTS);

  const [sendCampaignReminders] = useMutation(SEND_CAMPAIGN_REMINDERS, {
    refetchQueries: ["GetCampaign"],
  });

  const [deleteCampaign] = useMutation(DELETE_CAMPAIGN, {
    refetchQueries: ["GetCampaigns"],
  });

  const [excludeTeam] = useMutation(EXCLUDE_GROUP, {
    refetchQueries: ["GetCampaign"],
  });

  const [setCampaignGroupCategory] = useMutation(SET_CAMPAIGN_GROUPCATEGORY, {
    refetchQueries: ["GetCampaign"],
  });

  const { loading: sectionsLoading, data: sectionsData } =
    useQuery<SectionsResult>(GET_SECTIONS, { variables: { filters: {} } });
  const sections = sectionsData ? sectionsData.sections : [];

  const { loading: categoriesLoading, data: categoriesData } =
    useQuery<GroupCategoriesResult>(GET_GROUP_CATEGORIES, {
      variables: { filters: {} },
    });
  const categories = categoriesData ? categoriesData.groupCategories : [];

  const { loading: formsLoading, data: formsData } = useQuery<FormsResult>(
    GET_FORMS,
    { variables: { filters: {} } },
  );

  // TODO: SHIFT THIS FILTERING TO BACKEND!!!!
  const templates = formsData
    ? formsData.forms.filter(
        (form) =>
          form.isMine || form.isDirectShare || form.isShared || form.isGlobal,
      )
    : [];

  const [t] = useTranslation();

  const section =
    campaign && sections
      ? sections.find((target) => target.id === campaign?.sectionId)
      : undefined;

  // const template =
  //   campaign && templates
  //     ? templates.find((target) => target.id === campaign?.formId)
  //     : undefined;
  const template = campaign && campaign.form ? campaign.form : undefined;

  const { loading: usersLoading, data: usersData } = useQuery<UsersResult>(
    GET_USERS,
    {
      variables: {
        filters: {
          sections: campaign
            ? campaign.sectionId
              ? campaign.sectionId
              : undefined
            : undefined,
        },
      },
    },
  );
  const users = usersData ? usersData.users : [];

  const teams = campaign && campaign.groups ? campaign.groups : [];

  const { loading: responsesLoading, data: responsesData } =
    useQuery<ResponsesResult>(GET_RESPONSES_SUMMARY, {
      variables: {
        filters: {
          campaignId,
        },
      },
    });
  const responses = responsesData ? responsesData.responses : [];

  const showLoading =
    campaignLoading ||
    sectionsLoading ||
    formsLoading ||
    usersLoading ||
    responsesLoading ||
    categoriesLoading;

  if (!campaign) {
    const missingAssets = [];
    if (!campaign) {
      missingAssets.push("Survey");
    }
    // if (!template) {
    //   missingAssets.push("Template");
    // }
    return (
      <Layout
        breadcrumbs={[{ title: t("campaigns"), link: "/campaigns" }]}
        loading={showLoading}
      >
        {showLoading ? <></> : <>{missingAssets.join(", ")} not found</>}
      </Layout>
    );
  }

  async function handleUpdateAssignmentPoints(
    id: string,
    assignmentPoints: string,
  ) {
    if (!campaign) {
      return;
    }
    await updateAssignmentPoints({
      variables: {
        id,
        assignmentPoints,
      },
      context: {
        debounceKey: `campaign-${campaignId}`,
      },
      optimisticResponse: {
        updateAssignmentPoints: {
          id: campaign.id,
          __typename: "Campaign",
          assignmentEnabled: campaign.assignmentEnabled,
          assignmentPoints: assignmentPoints,
          activity: campaign.activity,
          dueDate: campaign.dueDate,
          form: campaign.form,
          formId: campaign.formId,
          groups: campaign.groups,
          name: campaign.name,
          released: campaign.released,
          sectionId: campaign.sectionId,
          state: campaign.state,
          submittedResponses: campaign.submittedResponses,
          totalResponses: campaign.totalResponses,
        },
      },
    });
  }

  async function handleCampaignChange(
    changedCampaign: CampaignType,
    activityLabel: string,
  ) {
    await updateCampaign({
      variables: {
        ...changedCampaign,
        activityLabel,
      },
      context: {
        debounceKey: `campaign-${changedCampaign.id}`,
      },
      optimisticResponse: {
        updateCampaign: {
          id: changedCampaign.id,
          __typename: "Campaign",
          assignmentEnabled: changedCampaign.assignmentEnabled,
          assignmentPoints: changedCampaign.assignmentPoints,
          assignmentGradingMode: changedCampaign.assignmentGradingMode,
          autoSendDate: changedCampaign.autoSendDate,
          autoCloseDate: changedCampaign.autoCloseDate,
          autoReleaseDate: changedCampaign.autoReleaseDate,
          activity: changedCampaign.activity,
          dueDate: changedCampaign.dueDate,
          form: changedCampaign.form,
          formId: changedCampaign.formId,
          groups: changedCampaign.groups,
          groupcategory: changedCampaign.groupcategory,
          name: changedCampaign.name,
          released: changedCampaign.released,
          sectionId: changedCampaign.sectionId,
          state: changedCampaign.state,
          submittedResponses: changedCampaign.submittedResponses,
          totalResponses: changedCampaign.totalResponses,
        },
      },
    });
  }

  async function handleDelete(campaign: CampaignType) {
    const confirmed = window.confirm(
      t("campaigns.confirmDelete", { name: campaign.name }) as string,
    );
    if (!confirmed) {
      return;
    }

    await deleteCampaign({
      variables: {
        id: campaign.id,
      },
    });

    navigate(`/campaigns`);
  }

  async function handleRemind(campaign: CampaignType) {
    const sent = await sendCampaignReminders({
      variables: {
        id: campaign.id,
      },
      context: {
        debounceKey: `campaign-${campaign.id}`,
      },
    });

    if (sent) {
      alert("Reminders sent successfully");
    } else {
      alert("No reminders to send");
    }
  }

  async function handleExclude(campaign: CampaignType, team: Team) {
    await excludeTeam({
      variables: {
        campaignId: campaign.id,
        groupId: team.id,
      },
      context: {
        debounceKey: `exclude-${campaign.id}-${team.id}`,
      },
    });
  }

  async function handleSetGroupCategory(
    campaign: CampaignType,
    category: GroupCategory | null,
  ) {
    await setCampaignGroupCategory({
      variables: {
        id: campaign.id,
        groupcategoryId: category ? category.id : null,
      },
      context: {
        debounceKey: `setGroupCategory-${campaign.id}-${
          category ? category.id : null
        }`,
      },
    });
  }

  return (
    <Layout
      breadcrumbs={[
        { title: t("breadcrumbs.campaigns"), link: "/campaigns" },
        { title: campaign.name },
      ]}
      loading={showLoading}
    >
      <CampaignEdit
        campaign={campaign}
        categories={categories}
        responses={responses}
        section={section}
        sections={sections}
        teams={teams}
        template={template}
        templates={templates}
        users={users}
        onChange={handleCampaignChange}
        onUpdateAssignmentPoints={handleUpdateAssignmentPoints}
        onRemind={handleRemind}
        onDelete={handleDelete}
        onExclude={handleExclude}
        onSetGroupCategory={handleSetGroupCategory}
      />
    </Layout>
  );
};
